import React from 'react'
import 'react-multi-carousel/lib/styles.css';
import './PraanganCarousel.scss'

function PraanganCarousel() {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
  return (
    <div className='pc' id="walk-through">
        {/* <Carousel infinite={true} responsive={responsive} arrows={false} showDots={true} autoPlay={true}>
            <div className="slide-one">
                <img src={slideOne} alt="" />
            </div>
            <div className="slide-two">
                <img src={slideTwo} alt="" />
            </div>
        </Carousel> */}
        <iframe width="100%" src="https://www.youtube.com/embed/mmomqww2NBg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

        </iframe>
        <div><div>
        <iframe width="50%" height="100%" src="https://www.youtube.com/embed/FbhQULOqtek?si=BymPH2pn8c5-IzzW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> </div>
        <div><iframe width="50%" height="100%" src="https://www.youtube.com/embed/t3kLn3aSJ14?si=rWrtKXvzBFRcYoqB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div></div>
    
     <div className='video'>
         <iframe width="100%" src="https://www.youtube.com/embed/eFiQsQW1RNw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     </div> <div className='video'>  <iframe width="100%" src="https://www.youtube.com/embed/KRGk_Ojk1kU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     </div><div className='video'>   <iframe width="100%" src="https://www.youtube.com/embed/qNqNuPOl4o8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     </div> <div className='video'>  <iframe width="100%" src="https://www.youtube.com/embed/mJVTBcxqD8M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     </div>
    </div>

   
  )
}

export default PraanganCarousel