import React from 'react'
import Container from '../Container/Container'
import {  IconButton } from '@mui/material'
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import './ReraContent.scss'

function ReraContent() {
  return (
    <div className="rera-content">
    
        <Container>
        <div className='d-flex rera-title'>
          {/* <div className="icon">
            <IconButton>
              <FileDownloadDoneIcon/>
            </IconButton>
          </div> */}
          <h1>Rera</h1>
          <h1>Disclaimer</h1>
        </div>
        
        <p>By using or accessing the Website you agree with the Disclaimer without any qualification or limitation. The Company reserves the right to terminate, revoke, modify, alter, add and delete any one or more of the terms and conditions of the website. The Company shall be under no obligation to notify the visitor of the amendment to the terms and conditions and the visitor shall be bound by such amended terms and conditions.</p>
        <p>The visuals and information contained herein marked as “artistic impression” that are artistic impressions being indicative in nature and are for general information purposes only. The visuals contained marked as “generic images” and other visuals /images /photographs are general images and do not have any correlation with the project.</p>
        <p>The imagery used on the Website may not represent actuals or may be indicative of the style only. Photographs of interiors, surrounding views and location may not represent actuals or may have been digitally enhanced or altered. These photographs may not represent actuals or may be indicative only. Computer generated images, walkthroughs and render images are the artist’s impression and are an indicative of the actual designs.</p>
        <p>No information given on this Website creates a warranty or expands the scope of any warranty that cannot be disclaimed under the applicable laws. The information on this Website is presented as general information and no representation or warranty is expressly or impliedly given as to its accuracy. Any interested party should verify all the information including designs, plans, specifications, facilities, features, payment schedules, terms of sales etc. independently with the Company prior to concluding any decision for buying.</p>
        <p>While enough care is taken by the Company to ensure that the information on the Website are up to date, accurate and correct, the readers/ users are requested to make an independent enquiry with the Company before relying upon the same. Nothing on the Website should be misconstrued as advertising, marketing, booking, selling or an offer for sale or invitation to purchase a unit in any project by the Company. The Company is not responsible for the consequences of any action taken by the viewer relying on such material/ information on this Website without independently verifying with the Company.</p>
        </Container>
        
    </div>
  )
}

export default ReraContent