import React from 'react'
import "./Comingsoon.scss"
import Container from '../../Container/Container'
import { useRef } from 'react'
import { useEffect } from 'react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import  gsap  from 'gsap';

function ComingSoon() {
  const bg = useRef();
  const title = useRef();
  const caption = useRef();

  useEffect(()=> {
    gsap.registerPlugin(ScrollTrigger);

    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: bg.current,
        scrub: false,
        // markers: true,
        start: `top ${(window.innerHeight) * 2/4}`,
        end: 'bottom bottom'
      },
    });

   

    t1.from (".coming-soon-left h1 span", {
      opacity: 0,
      y: '20px',
      stagger: 0.04,
    })

    .from(caption.current, {
      opacity: 0,
      y: '20px'
    }, 0.7)

  }, [])
  return (
    <div className='coming-soon' ref={bg}>
        <Container>

          <div className="coming-soon-left">
                <h1 ref={title}>
                  <span>Y</span>
                  <span>O</span>
                  <span>U</span>
                  <span>R</span>
                  <span> </span>
                  <span>H</span>
                  <span>A</span>
                  <span>P</span>
                  <span>P</span>
                  <span>I</span>
                  <span>N</span>
                  <span>E</span>
                  <span>S</span>
                  <span>S</span>
                  <span> </span>
                  <span>C</span>
                  <span>O</span>
                  <span>U</span>
                  <span>R</span>
                  <span>T</span>
                  <span>Y</span>
                  <span>A</span>
                  <span>R</span>
                  <span>D</span>
                  
                </h1>
            </div>
            <div className="coming-soon-right">
                <h1 ref={caption}>
                    Launched on 24 December 
                    {/* <span>24 December 2022</span> */}
                </h1>
            </div>
        </Container>
    </div>
  )
}

export default ComingSoon