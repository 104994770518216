import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Container from '../Container/Container'
import bg1 from '../../assets/Image/about-pattern2.png'
import './BlogInnerContentTwo.scss'

function BlogInnerContentTwo(props) {
  return (
    <div className='bic2'>
        <img className='bg2' src={bg1} alt="" />
        <Container>
            <Row>
                <Col xs={0} md={4}>
                </Col>

                <Col xs={12} md={8}>
                    <div className="bic2__text2">
                        {props.titleExtra.length? 
                            <h2>
                                {props.titleExtra}
                            </h2>: ''    
                        }

                        {props.contentExtraParaOne.length? 
                            <p>
                                {props.contentExtraParaOne}
                            </p>: ''    
                        }

                        {props.contentExtraParaTwo.length? 
                            <p>
                                {props.contentExtraParaTwo}
                            </p>: ''    
                        }

                        {props.contentExtraParaThree.length? 
                            <p>
                                {props.contentExtraParaThree}
                            </p>: ''    
                        }

                        <h2>
                            {props.titleMainOne}
                        </h2>
                        

                        {props.titleOne.length? 
                            <h4>
                                {props.titleOne}
                            </h4>

                            : ''
                        }
                        <p>
                            {props.contentOne}
                        </p>

                        {props.titleTwo.length? 
                            <h4>
                                {props.titleTwo}
                            </h4>

                            : ''
                        }
                        
                        <p>
                            {props.contentTwo}
                        </p>

                        {props.titleThree.length? 
                            <h4>
                                {props.titleThree}
                            </h4>

                            : ''
                        }
                        
                        <p>  
                            {props.contentThree}
                        </p>

                        {props.titleFour.length? 
                            <h4>
                                {props.titleFour}
                            </h4>

                            : ''
                        }
                        
                        <p>  
                            {props.contentFour}
                        </p>
                        
                        {props.titleMainTwo.length ? 
                        <h2>
                            {props.titleMainTwo}
                        </h2>
                        : ''    
                        }

                        {props.titleFive.length? 
                            <h4>
                                {props.titleFive}
                            </h4>

                            : ''
                        }
                        
                        {props.contentFive.length ? 
                        <p>  
                            {props.contentFive}
                        </p>
                        : ''
                        }

                        {props.titleSix.length? 
                            <h4>
                                {props.titleSix}
                            </h4>

                            : ''
                        }
                        
                        {
                        props.contentSix.length ? <p>  
                            {props.contentSix}
                        </p>
                        : ''
                        }

                        {props.titleSeven.length? 
                            <h4>
                                {props.titleSeven}
                            </h4>

                            : ''
                        }
                        
                        {
                        props.contentSeven.length ? <p>  
                            {props.contentSeven}
                        </p>
                        : ''
                        }

                        {props.titleEight.length? 
                            <h4>
                                {props.titleEight}
                            </h4>

                            : ''
                        }
                        
                        {
                        props.contentEight.length ? <p>  
                            {props.contentEight}
                        </p>
                        : ''
                        }

                        

                        {props.titleNine.length? 
                            <h4>
                                {props.titleNine}
                            </h4>

                            : ''
                        }
                        
                        {
                        props.contentTen.length ?  
                        <p>  
                            {props.contentTen}
                        </p>
                        : ''
                        }

                        {props.titleTen.length? 
                            <h4>
                                {props.titleTen}
                            </h4>

                            : ''
                        }
                        
                        {
                        props.contentNine.length ?  
                        <p>  
                            {props.contentNine}
                        </p>
                        : ''
                        }

                        {props.titleMainThree.length? 
                            <h2>
                                {props.titleMainThree}
                            </h2>

                            : ''
                        }

                        {props.titleMainContentThree.length? 
                            <p>
                                {props.titleMainContentThree}
                            </p>

                            : ''
                        }
                        

                    </div>
                </Col>              
            </Row>
        </Container>  
    </div>
  )
}

export default BlogInnerContentTwo