import './App.scss';
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import AnimatedRoutes from './components/AnimatedRoutes';
import gsap from 'gsap/dist/gsap';
import {ScrollTrigger} from 'gsap/all';
import { useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger)

function App() {

  useEffect(()=>{
    ScrollTrigger.refresh();
    // ScrollTrigger.killAll();
  }, [])

  return (
    
      <div className="App">
        <Router>
          <ScrollToTop/>
          <AnimatedRoutes/>
        </Router>
    </div>
  );
}

export default App;




