import BlogOneImageOne from '../assets/Image/Blogs/blog1image1.jpg';
import BlogOneImageTwo from '../assets/Image/Blogs/blog1image2.jpg';
import BlogOneBanner from '../assets/Image/Blogs/blog1banner.jpg';

import BlogTwoImageOne from '../assets/Image/Blogs/blog2image1.jpg'
import BlogTwoImageTwo from '../assets/Image/Blogs/blog2image2.jpg'
import BlogTwoBanner from '../assets/Image/Blogs/blog2banner.jpg'

import BlogThreeImageOne from '../assets/Image/Blogs/Blog3Image1.jpg'
import BlogThreeImageTwo from '../assets/Image/Blogs/Blog3Image2.jpg'
import BlogThreeBanner from '../assets/Image/Blogs/Blog3Banner.jpg'

import BlogFourImageOne from '../assets/Image/Blogs/blog4image1.jpg'
import BlogFourImageTwo from '../assets/Image/Blogs/blog4image2.jpg'
import BlogFourBanner from '../assets/Image/Blogs/blog4banner.jpg'

import BlogFiveImageOne from '../assets/Image/Blogs/blog5image1.jpg'
import BlogFiveImageTwo from '../assets/Image/Blogs/blog5image2.jpg'
import BlogFiveBanner from '../assets/Image/Blogs/blog5banner.jpg'

import BlogSixImageOne from '../assets/Image/Blogs/blog6image1.jpg'
import BlogSixImageTwo from '../assets/Image/Blogs/blog6image2.jpg'
import BlogSixBanner from '../assets/Image/Blogs/blog6banner.jpg'

export const BlogData = [ 
    {
        id: '1', 
        title: 'Does The importance of open area in a city space Really Live up to the Hype?',
        intro: "High human population density and numerous human-built characteristics define an urban region. Thus, in the crowded urban matrix, the necessity for open spaces increases. Any type of green belt, whether man-made or natural, might be an open area, a body of water, or both. Cities' urban morphology demonstrates how the process of urbanization results in a steady loss of open space. Any metropolitan region has to have open areas to survive. ",
        
        banner: BlogOneBanner,

        imageOne: BlogOneImageOne,
        imageTwo: BlogOneImageTwo,

        titleExtra: "",
        contentExtraParaOne: "",
        contentExtraParaTwo: "",
        contentExtraParaThree: "",

        contentOneParaOne: "These open spaces offer areas for leisure and relaxation, a venue for social contacts and cultural exchange in various metropolitan neighborhoods, advantages for the environment and human health, socioeconomic advantages, etc. ",
        contentOneParaTwo: "When there is a strong bond between the human and the surroundings, space becomes productive. Through the utilization of community services and social interaction, a person in a residential area can create a sense of security and belonging.",
        contentOneParaThree: '',

        contentTwoMainTitleOne: 'Characteristics of Open Spaces',

        contentTwoTitleOne: 'Distinctiveness',
        contentTwoParaOne: 'This implies that the spaces have a diversity of functions, constructed forms, features, colours, and materials that give the spaces and buildings their own identity within the broader character of the nearby urban environment. The spaces also reflect the local character of the region',

        contentTwoTitleTwo: 'Accessibility',
        contentTwoParaTwo: 'This entails strong links that lead to urban open spaces as well as good connectivity from these places to other areas of the city.',

        contentTwoTitleThree: 'Passive engagement',
        contentTwoParaThree: 'This means that individuals maintain their composure and continue to observe. It is offered by features like fountains, vistas, public art, etc. in cities.',

        contentTwoTitleFour: 'Comfort',
        contentTwoParaFour: "Physical comfort (pleasant and ample seating places, etc.), social and physical comfort (privacy, relaxation, etc.) are all variables that contribute to one's level of comfort. ",

        contentTwoMainTitleTwo: 'Open Spaces & their benefits',

        contentTwoTitleFive: 'Environmental Benefits',
        contentTwoParaFive: "It has been found that trees enhance air quality by eliminating pollutants and supplying oxygen. Additionally, green areas with less paving have a cooling impact, lowering summer temperatures. This reduces summer humidity and lowers energy costs associated with cooling buildings. According to a recent research, rooftops with grass and plants outperform asphalt and gravel roofs in keeping buildings cool while also offering residents a more appealing location to hang out. ",

        contentTwoTitleSix: 'Fitness',
        contentTwoParaSix: "Parks, hiking trails, playgrounds, and open fields are excellent places to go for recreation. These areas provide areas for physical activity, whether planned or unplanned, encouraging individuals to stroll and exercise. This is crucial for city dwellers who cannot afford fitness classes or club subscriptions.",

        contentTwoTitleSeven: "Mental Wellness",
        contentTwoParaSeven: "Both physical and mental health are greatly improved by exercise. By offering quiet areas to pause and reflect away from city noise and bustle, open spaces help improve feelings of wellbeing. This offers a break from the city and helps to lower stress.",

        contentTwoTitleEight: "Community Benefits",
        contentTwoParaEight: "While open areas are places for relaxation, they may also serve as social gathering places where people can mingle, play, and converse. Open space can be utilized for social gatherings, cultural activities, and cooperative leisure activities. Whether via a planned event or activity or just because they are areas where people congregate, these locations encourage interaction between members of the community. As individuals get to know others in their area, it fosters a feeling of community that is advantageous to both adults and kids. Urban green areas benefit the environment, encourage physical activity among city dwellers, improve their mental health, and provide a sense of neighborhood. ",


        contentTwoTitleNine: '',
        contentTwoParaNine: '',

        contentTwoTitleTen: '',
        contentTwoParaTen: '',

        contentTwoMainTitleThree: 'Make your living environment more pleasant!',
        contentTwoMainParaThree: "Open spaces in the city can take on a variety of meanings and purposes, which can lead to associations between a space and its users. People do often have a connection to both their physical and social environments. When the environment succeeds in integrating itself into their daily lives and fulfills their wants, expectations, and beliefs, they are drawn to open spaces. We have always been on point with building living spaces that always inculcate the concept of open spaces. "
    },

    {
        id: '2', 
        title: 'The Emerging Trends in Residential Real Estate',
        intro: "In an otherwise lackluster 2020 for the real estate industry, the residential segment was the one bright spot.  Residential sales saw a negative impact between April and June 2020 as a result of the unpredictability brought on by the COVID-19 virus outbreak, but they experienced a significant rebound between October 2020 and March 2021. The market showed a noteworthy rebound by posting enormous sales in the third and fourth quarters of 2020–21.",
        
        banner: BlogTwoBanner,

        imageOne: BlogTwoImageOne,
        imageTwo: BlogTwoImageTwo,

        titleExtra: "",
        contentExtraParaOne: "",
        contentExtraParaTwo: "",
        contentExtraParaThree: "",

        contentOneParaOne: "The path for a recovery was paved by initiatives such the decrease of stamp duty, cheap home interest rates, and the awareness of the value of homeownership following the lockdown. ",
        contentOneParaTwo: "New trends and a change in consumer behavior are creating a chance for the sector to reinvent itself. Here are some characteristics that customers are seeking.",
        contentOneParaThree: '',

        contentTwoMainTitleOne: '',

        contentTwoTitleOne: 'Trustworthy developers',
        contentTwoParaOne: 'Due to this exceptional crisis, it is anticipated that the residential real estate market will continue to consolidate, giving corporate developers the chance to diversify their portfolios across premium locales. In order to attract clients, many developers today are offering deals and discounts. Homebuyers should exercise caution and choose reputable builders who provide appropriate communication and good value.',

        contentTwoTitleTwo: 'Biophilic design',
        contentTwoParaTwo: "People's growing levels of stress and anxiety nowadays are having an impact on their health, as well as their interpersonal and employment relationships. Numerous psychologists contend that tranquility and calmness can be attained by spending more time outside and in nature. As a result, a lot of buyers are increasingly choosing to live in houses with plenty of windows and greenery. Numerous new homes are exhibiting the developing trend of 'biophilic design,' which aims to integrate the natural and built environments by utilizing natural materials, spatial layouts, and vistas or access points to the outdoors. ",

        contentTwoTitleThree: 'Neighborhood life and townships',
        contentTwoParaThree: "The ecosystem's ability to support itself is community living's most significant advantage. Home buyers' preferences have changed as a result of the pandemic; they are now looking for secure, well-designed, and self-sufficient areas that provide everything under one roof through expansive communities, such as co-working spaces, cafés, convenience stores, fitness centers, and reading lounges, among others, within the township. ",

        contentTwoTitleFour: 'Home office',
        contentTwoParaFour: "Remote learning and working from home are the new standard for families, and this has brought attention to the necessity for quiet areas. Having a dedicated, effective home office is now more important than ever following the shutdown. Buyers are looking for homes that may fit their lifestyle and working preferences, as well as developments that offer practical luxury with effective plans. They are seeking environments that will enable them to work more productively and successfully. ",


        contentTwoMainTitleTwo: '',

        contentTwoTitleFive: 'E-tours and online experience',
        contentTwoParaFive: "With brand-new dynamics in home buying emerging. Now, before choosing a site visit, home buyers prefer to visit the website first, arrange a virtual appointment, and take an e-tour. Developers are providing end-to-end digital experiences for house purchasers with investments in both AR (augmented reality) and VR (virtual reality) to assure an experience that is practically identical to a physical site visit while also taking the safety of the clients into consideration. These 360-degree virtual tours, which can be taken from the convenience and security of one's home, have revolutionized the industry. ",

        contentTwoTitleSix: 'The client is king',
        contentTwoParaSix: "The years to come will be the most advantageous for homebuyers due to developers' numerous profitable plans. The market is fueled by the introduction of tactics that increase demand and provide buyers with motivation to make real estate transactions. Ready-to-move-in flats have the highest demand among buyers in the residential sector. Additionally, government programs like 'Housing for everyone' have increased sales in this market. ",

        contentTwoTitleSeven: "",
        contentTwoParaSeven: "",

        contentTwoTitleEight: "",
        contentTwoParaEight: "",

        contentTwoTitleNine: '',
        contentTwoParaNine: " ",

        contentTwoTitleTen: '',
        contentTwoParaTen: " ",

        contentTwoMainTitleThree: 'To Sum Up',
        contentTwoMainParaThree: "Young, educated, and concerned about their health, today's home buyers. For a brighter future, their altering behavioral patterns are in harmony. It is essential for developers to appeal to consumers in order to adjust to the new norm. These changes will guarantee that the developers are able to sustain themselves over the long run and remain relevant in this competitive market.",

        
    },

    {
        id: '3', 
        title: 'The 10 Best Things To Keep In Mind While Looking For A New House',
        intro: "It is believed that the last stretch is never simple. The same applies to navigating the real estate market. Before you can sign on the dotted line and purchase your ideal home, you need to put a lot of effort and more than just money into it. ",
        
        banner: BlogThreeBanner,

        imageOne: BlogThreeImageOne,
        imageTwo: BlogThreeImageTwo,

        titleExtra: "",
        contentExtraParaOne: "",
        contentExtraParaTwo: "",
        contentExtraParaThree: "",

        contentOneParaOne: "But, don’t worry, we are here to guide you through the whole process of it. In order to be ready, the first & most thing to do is to address some questions first.  ",
        contentOneParaTwo: "Before signing up for your ideal home, we'll help you navigate this maze by asking you these 10 questions.",
        contentOneParaThree: '',

        contentTwoMainTitleOne: 'Let the Q&A Begin!',

        contentTwoTitleOne: 'Why do you want to purchase the property?',
        contentTwoParaOne: 'Do you intend to live there, rent it out, or give the house to your kids? Since the registration of a property might take anywhere between two and three months, it is crucial that you understand why you are purchasing the home. You can save a lot of time and money if you select ahead of time in whose name you wish to purchase the property.',

        contentTwoTitleTwo: 'What paperwork is required to guarantee a clear title?',
        contentTwoParaTwo: "Any potential buyer would be put off by your lengthy list of legal requirements. If you don't know how to eliminate the superfluous ones, the problem will just become worse",

        contentTwoTitleThree: 'Have you given tax planning any thought?',
        contentTwoParaThree: 'Purchasing a home can have a variety of effects on how you manage your taxes. It is advised to purchase a home for rental purposes if you are purchasing a home for the second time since only one property is exempt from house tax under wealth tax.',

        contentTwoTitleFour: 'Have you met the seller of the property?',
        contentTwoParaFour: "It is essential that you meet the seller if you are purchasing the property through a middleman like a real estate agent or broker. If the property is not free of encumbrances, agents will often avoid or postpone this meeting. Meeting the seller provides you the chance to compare the real-life picture with the one on the registration paperwork.  ",


        contentTwoMainTitleTwo: '',

        contentTwoTitleFive: 'Is the property unencumbered?',
        contentTwoParaFive: "You can end up embroiled in pointless legal battles if the vendor lacks a solid and marketable title. You must make sure that your title is appropriate. You should pay the location a few surprise visits to make sure it is an unencumbered property in order to avoid any misunderstandings. Additionally, you must confirm at the registrar's office that the sale deed is not in the name of another person. ",

        contentTwoTitleSix: "Is the price you're paying reasonable?",
        contentTwoParaSix: "The most important factor is the asset's value. Make a comparison of nearby properties that are similar before you sign any documents. Ask your neighbours and the proprietors of the nearby shops about the going rates in the community. Calculating the potential future appreciation of the property is also necessary.",

        contentTwoTitleSeven: "How desirable is the location?",
        contentTwoParaSeven: "Find out some fundamental details before deciding on a home, such as if the builder will install municipal facilities like roads, power, and water supplies, as well as whether he will offer sufficient security.",

        contentTwoTitleEight: "Do you like the fundamental design and structure?",
        contentTwoParaEight: "You must thoroughly inspect the property's fundamental and structural design after confirming the property's location and ownership. Verify that the structure is free from fractures and seepages. Additionally, the design of the building should allow for interior changes whenever necessary. Nowadays, it's common to renovate residential properties to create a conceptual living style. It is a cost-effective method of converting your home into a luxurious apartment. ",

        contentTwoTitleNine: 'Do you have any extra funds set aside?',
        contentTwoParaNine: 'Purchasing a home is a costly investment. If you have a budget set aside for your investment, it is a good idea to have some cash on hand to cover unforeseen expenses. After you have complied with all the rules, there are several costs you cannot avoid. Always be prepared with some cash on hand. '
        
       , contentTwoTitleTen: "Keeping size & floor plan into consideration? ",
         contentTwoParaTen: "",

        contentTwoMainTitleThree: 'To Sum Up',
        contentTwoMainParaThree: "If you don't use the area, don't be impressed by a huge house or a house with unusual features. Prior to house searching, decide on the correct size house for you and your desired floor layout. Then, strive to stay as true to that as you can. Also keep in mind that your taxes and energy costs will go up. Additionally, it will need more work to maintain the house clean and more furnishings to furnish it. Therefore, take into account the layout and space you'll need for your lifestyle both now and for the remainder of your stay in the property. ",
    
    },

    {
        id: '4', 
        title: 'The Must-Have Amenities in 2022: A Guide On How To Pick Up Flats',
        intro: "In addition to the house's actual floor layout, there are other crucial choices you must make while shopping for an apartment. Gated communities frequently provide a number of facilities, while autonomous apartments have their own benefits. Before choosing your new residence, consider the following questions.",
        
        banner: BlogFourBanner,

        imageOne: BlogFourImageOne,
        imageTwo: BlogFourImageTwo,

        titleExtra: "",
        contentExtraParaOne: "",
        contentExtraParaTwo: "",
        contentExtraParaThree: "",

        contentOneParaOne: "Make a list and take a look at your existing way of life. What do you now have, what do you utilise, and what do you feel your life is lacking? You'll have a better notion of the facilities to search for thanks to this.",
        contentOneParaTwo: "The decision is frequently based on that. You could wish to reside in a specific area yet feel enticed to transfer to a gated neighborhood farther away. Consider carefully how living far from your place of employment will impact your life. What kind of toll are you paying to move into a complex? Is security the main priority?        ",
        contentOneParaThree: '',

        contentTwoMainTitleOne: 'Our vision for your homes & amenities',

        contentTwoTitleOne: '',
        contentTwoParaOne: "We provide a blend of luxurious living and the outdoors. A work of art, they frequently are. Practically made for a better life at the same time. By employing premium building materials that provide homes a longer and more sustainable life, we create designs that enhance neighbourhoods. We use cutting-edge technology to implement our functional, customer-focused designs, which promote hassle-free living.",

        contentTwoTitleTwo: 'Accessibility',
        contentTwoParaTwo: 'This entails strong links that lead to urban open spaces as well as good connectivity from these places to other areas of the city.',

        contentTwoTitleThree: 'Passive engagement',
        contentTwoParaThree: 'This means that individuals maintain their composure and continue to observe. It is offered by features like fountains, vistas, public art, etc. in cities.',

        contentTwoTitleFour: 'Comfort',
        contentTwoParaFour: "Physical comfort (pleasant and ample seating places, etc.), social and physical comfort (privacy, relaxation, etc.) are all variables that contribute to one's level of comfort. ",


        contentTwoMainTitleTwo: 'Open Spaces & their benefits',

        contentTwoTitleFive: 'Environmental Benefits',
        contentTwoParaFive: "It has been found that trees enhance air quality by eliminating pollutants and supplying oxygen. Additionally, green areas with less paving have a cooling impact, lowering summer temperatures. This reduces summer humidity and lowers energy costs associated with cooling buildings. According to a recent research, rooftops with grass and plants outperform asphalt and gravel roofs in keeping buildings cool while also offering residents a more appealing location to hang out. ",

        contentTwoTitleSix: 'Fitness',
        contentTwoParaSix: "Parks, hiking trails, playgrounds, and open fields are excellent places to go for recreation. These areas provide areas for physical activity, whether planned or unplanned, encouraging individuals to stroll and exercise. This is crucial for city dwellers who cannot afford fitness classes or club subscriptions.",

        contentTwoTitleSeven: "Mental Wellness",
        contentTwoParaSeven: "Both physical and mental health are greatly improved by exercise. By offering quiet areas to pause and reflect away from city noise and bustle, open spaces help improve feelings of wellbeing. This offers a break from the city and helps to lower stress.",

        contentTwoTitleEight: "Community Benefits",
        contentTwoParaEight: "While open areas are places for relaxation, they may also serve as social gathering places where people can mingle, play, and converse. Open space can be utilized for social gatherings, cultural activities, and cooperative leisure activities. Whether via a planned event or activity or just because they are areas where people congregate, these locations encourage interaction between members of the community. As individuals get to know others in their area, it fosters a feeling of community that is advantageous to both adults and kids. Urban green areas benefit the environment, encourage physical activity among city dwellers, improve their mental health, and provide a sense of neighborhood. ",


        contentTwoTitleNine: 'Make your living environment more pleasant!',
        contentTwoParaNine: 'Open spaces in the city can take on a variety of meanings and purposes, which can lead to associations between a space and its users. People do often have a connection to both their physical and social environments. When the environment succeeds in integrating itself into their daily lives and fulfills their wants, expectations, and beliefs, they are drawn to open spaces. We have always been on point with building living spaces that always inculcate the concept of open spaces. '
    
        ,contentTwoTitleTen: "",
         contentTwoParaTen: "",

        contentTwoMainTitleThree: 'Make your living environment more pleasant!',
        contentTwoMainParaThree: '',
    }, 

    {
        id: '5', 
        title: 'How to Make the Most of Your First Time Home Buying Experience in India',
        intro: "It will take time, effort, and dedication on your part to look for a house. For many individuals, owning a house is a goal that they want to one day realise. However, to make that dream a reality, you must work hard and carefully consider your alternatives while taking into account your finances and stage of life. ",
        
        
        banner: BlogFiveBanner,

        imageOne: BlogFiveImageOne,
        imageTwo: BlogFiveImageTwo,

        contentOneParaOne: "In terms of living area, location, and value, the house need to be fairly future-proof, meaning that it ought to meet your family's needs for many years to come. You need to be particularly cautious and have a thorough awareness of the procedures involved if you're buying a property for the first time.",
        contentOneParaTwo: " ",
        contentOneParaThree: '',

        titleExtra: "What type of home(s) may suit you the best",
        contentExtraParaOne: "Knowing the kind of home you want to buy is more important than anything else before we go on to buying a property buying advice. ",
        contentExtraParaTwo: "You may choose the property that best suits you by taking into account your budget, maintenance expenses, and the investment type. You have a choice of flats, houses, lots, villas, etc. ",
        contentExtraParaThree: "But selecting the ideal property may be difficult, particularly if it's your first time. Therefore, getting advice from someone who has just purchased a house or has already invested in real estate might be helpful.",

        contentTwoMainTitleOne: 'Tips for First Time Home Buyers in India',

        contentTwoTitleOne: 'Which housing suits you the best.',
        contentTwoParaOne: 'There is no shortage of housing options nowadays. From a modest studio flat to a mansion, everything is available. Choose the style of home that best suits your requirements. Think about whether you would want to buy a property that has already been built or one that can be customised to your needs.',

        contentTwoTitleTwo: 'Get that location right',
        contentTwoParaTwo: "Consider the neighbourhood, the community of the building, and their laws and regulations while picking the place. You can want a home in a quiet neighbourhood or one that is close to a market, depending on your preferences. Similar to this, some individuals favour homes with a little garden or other greenery surrounding the structure. In addition, you should choose a home in the appropriate neighbourhood based on your daily routine, your place of employment, and your family's demand for a decent school system. Additionally, choose a place where there is a nearby hospital where you may go in case of a medical emergency. Some people require convenient access to public transportation, such as being close to a metro station, bus stop, or train station. When buying a home, carefully consider each of these variables. ",

        contentTwoTitleThree: 'Vastu-compatibility of your home? Check',
        contentTwoParaThree: "Although individual beliefs may differ, the majority of homebuyers do take the homes' Vastu compliance into consideration. As a result, the majority of real estate developers ensure that the homes they construct comply with Vastu. While purchasing a property, you may also look at this element.",

        contentTwoTitleFour: 'Amenities can make a difference in your choice',
        contentTwoParaFour: "The concept of a house has been fundamentally altered by modern housing. These days, apartment buildings provide a variety of facilities, including gyms, spas, swimming pools, clubhouses, etc. The residents are responsible for paying for these facilities, though. The necessity of these facilities and if they complement your personal lifestyle should both be carefully considered. Having an apartment building with a gaming area, for instance, may be a wise investment if you have small children. If you own automobiles, don't forget to factor in parking spaces or garages because you'll need room for them.",


        contentTwoMainTitleTwo: '',

        contentTwoTitleFive: 'Fund Allocation',
        contentTwoParaFive: "Even if you may have saved up a portion of the money, you may need to think about getting a house loan to cover the difference.Think about obtaining a mortgage from a reputable housing financing organisation that offers fast approvals and disbursements, long-term mortgages, affordable fees, etc.In addition to helping you finance your ideal house, a mortgage offers tax advantages on interest and principal repayments.",

        contentTwoTitleSix: "",
        contentTwoParaSix: "",

        contentTwoTitleSeven: "",
        contentTwoParaSeven: "",

        contentTwoTitleEight: "",
        contentTwoParaEight: "",

        contentTwoTitleNine: '',
        contentTwoParaNine: '',
    
        contentTwoTitleTen: '',
        contentTwoParaTen: '',

        contentTwoMainTitleThree: "Let’s sum up!",
        contentTwoMainParaThree: 'In conclusion, buying a home for the first time in India can be a daunting task, but it is definitely doable with careful planning and execution. Hopefully this article has given you a better understanding of what to expect when going through the process. If you are still unsure, seek out the help of a professional like Prime Group who can guide you through every step of the way.',
        
    }, 
    {
        id: '6', 
        title: "Female Homeowners: Why Buying A Home Is One Of The Best Decisions You'll Ever Make",
        intro: "High human population density and numerous human-built characteristics define an urban region. Thus, in the crowded urban matrix, the necessity for open spaces increases. Any type of green belt, whether man-made or natural, might be an open area, a body of water, or both. Cities' urban morphology demonstrates how the process of urbanization results in a steady loss of open space. Any metropolitan region has to have open areas to survive. ",
        
        banner: BlogSixBanner,

        imageOne: BlogSixImageOne,
        imageTwo: BlogSixImageTwo,


        contentOneParaOne: "Let’s get straight to the point. Women have traditionally been encouraged to prioritize gold investments since they have been raised in a culture where marriage, women, and gold are associated. As more women achieve financial independence and as women are given preference under property laws, this perspective is quickly shifting.",
        contentOneParaTwo: "The decision to choose and purchase a home, which was formerly mostly the responsibility of the family's male members, is increasingly being made by them as well. In terms of women purchasing homes in the nation, the real estate industry is expanding.",
        contentOneParaThree: '',

        titleExtra: "A change in perspective for the better",
        contentExtraParaOne: "This observation is rather general. If you look at the names in some of the newer neighbourhoods, you could see that some of the houses do have the name of the women on the name plates. The power of compounding may be most effectively used by a financially knowledgeable woman to ensure her future. She may teach children about money conversations at a young age.",
        contentExtraParaTwo: "However, at the same time, real estate may provide revenue that can be used to pay the mortgage in addition to being an investment. Renting out your home has financial advantages since you may deduct municipal taxes from the revenue received.  ",
        contentExtraParaThree: "Comparatively speaking, investing in real estate offers a better level of security. While mutual funds are unreliable and dangerous, gold may be stolen and has no tax advantages. On the other hand, a property may serve as a home, a source of income, and a long-term. investment all at once, depending on your needs.",

        contentTwoMainTitleOne: 'Why investing in the real estate for women will be an advantageous affair',

        contentTwoTitleOne: 'Tax benefit',
        contentTwoParaOne: 'In India, a borrower of a home loan is eligible for a tax deduction under sections 80C and 24 for a maximum of Rs 1.5 lakh and Rs 2 lakh, respectively, on the repayment of the principal and interest on the loan.',
        contentTwoParaTwo: 'Each applicants contribution toward the repayment of Principal and Interest will determine how much they get in benefits.',
        contentTwoParaThree: 'Both spouses may claim Income Tax advantages when submitting an IT return when a couple applies for a loan jointly. The couples benefits might effectively double up as a result, leaving them with additional money in their wallets.',
        
        contentTwoTitleTwo: 'Low-Interest Rate Home Loans',
        contentTwoParaOne: 'Applications for home loans from women are encouraged. Most lenders, including banks and NBFCs, provide main women applicants discounts ranging from 5 to 20 basis points.',
        contentTwoParaTwo: 'Thus, by securing a cheaper rate loan arrangement with a woman as the principal applicant, you can save lakhs of rupees.',
        contentTwoParaThree: 'Before committing to a home loan, a potential borrower should look into alternative options, such as relaxed rules, a partial waiver of the processing cost, lowering balance EMIs, and repayment ease.',
        contentTwoParaFour: 'Property Taxes & stamp duty concessions- Along with Delhi, a number of other states, including Bihar, Haryana, Punjab, Maharashtra, Orissa, Uttar Pradesh, and Uttarakhand, provide women who own homes a stamp duty exemption. The stamp duty rate might be reduced by 0.5 to 3% for female house owners nationwide.',
        contentTwoParaFive: 'Check the benefits that are offered in your specific area as the slab rates differ by state. Depending on the taxpayers gender, some states even provide different property tax rates. Women should own the property, nevertheless, in order to profit from them.',

        contentTwoTitleThree: 'A better leasing capability',
        contentTwoParaOne: 'Property has the flexibility to be used when needed, unlike gold or mutual funds. For instance, you may rent it out when not in use to bring in a consistent income.',
        contentTwoParaTwo: 'This is a far better use of your wealth and has further tax benefits. Additionally, it is free from the ownership hazards associated with renting.',

        contentTwoTitleFour: '',
        contentTwoParaFour: "",


        contentTwoMainTitleTwo: '',

        contentTwoTitleFive: '',
        contentTwoParaFive: "",

        contentTwoTitleSix: '',
        contentTwoParaSix: "",

        contentTwoTitleSeven: "",
        contentTwoParaSeven: "",

        contentTwoTitleEight: "",
        contentTwoParaEight: "",

        contentTwoMainTitleThree: '',

        contentTwoTitleNine: '',
        contentTwoParaNine: '',

        contentTwoTitleTen: '',
        contentTwoParaTen: '',

        contentTwoMainTitleThree: "",
        contentTwoMainParaThree: '',

    }
]