import React from 'react'
import Container from '../Container/Container'
import {Link} from 'react-router-dom'
import './Footer.scss'
import logo from "../../assets/Image/logo-footer.png";
import {  IconButton } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { FaFacebookSquare,FaInstagramSquare,FaMobile,FaYoutube,FaMapMarker } from "react-icons/fa";

function Footer() {
  return (
    <div className='footer'>

      <Container>
        <div className='footer-container'>
          <span className='logo'>
            <img src={logo} alt="" />
          </span>
          <div className='footer-links'>
            <a href="https://www.instagram.com/primegroupahm/">
              <p>
                <span><FaInstagramSquare /></span>Instagram
              </p>
            </a>

            <a href="https://www.youtube.com/channel/UCrYkdodK1_W3-P4bDuayIWA">
              <p>
                <span><FaYoutube /></span>Youtube
              </p>
            </a>
            
            <a href="https://www.facebook.com/primegroupahm">
              <p>
                <span><FaFacebookSquare /></span>Facebook
              </p>
            </a>
            {/* <p><span><FaYoutube /></span>Youtube</p> */}
            <a href="/rera">
              
              <p>
                <span>
                  <CheckCircleOutlineIcon/>
                </span>
                Rera
              </p>
            </a>

            <a href="">
              
            </a>
          </div>

        </div>
        <div className='footer-container-2'>
          <h1>We build great experiences.
          </h1>
          <p><span><FaMapMarker /></span>FP No. 121/2, Near Apple Green Bungalows, S.G. Highway, Chharodi, Ahmedabad- 382481.</p>
          <p><span><FaMobile /></span>+91 95123 35511</p>
        </div>
      </Container>
    </div>
  )
}

export default Footer