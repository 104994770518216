import React from 'react'
import BlogHero from '../components/BlogHero/BlogHero'
import BlogsMain from '../components/BlogsMain/BlogsMain'
import Footer from '../components/Footer/Footer'
import GreenFooter from '../components/Footer/GreenFooter'
import Header from '../components/Header/Header'

function Blogs() {
  return (
    <div>
        <Header position={'relative'}/>
        <BlogHero/>
        <BlogsMain/>
        <GreenFooter/>
        <Footer/>
    </div>
  )
}

export default Blogs