import { React, useRef, useEffect } from 'react'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from 'gsap';
import Container from "../../Container/Container";
import { FaMailBulk, FaMobile, FaUserAlt, FaMapMarker } from "react-icons/fa";
import ss from '../../../assets/Image/ss.jpg'

import "./GetInTouch.scss";
function GetInTouch() {
    
    return (
        <div className='get-in-touch'>

            <div className='get-in-touch-content'>

                <Container>
                    <h1>GET IN TOUCH
                        WITH US</h1>

                    <div>
                        <span><FaMapMarker /></span>
                        <p>FP No. 121/2, Near Apple Green Bungalows, S.G. Highway, Chharodi, Ahmedabad- 382481.</p>
                    </div>
                    <div>
                        <span><FaMobile /></span>
                        <p>+91 95123 35511</p>
                    </div>
                    <div>
                        <span><FaMailBulk /></span>
                        <p>info@primedeveloper.group</p>
                    </div>
                    {/* <div>
                        <span><FaUserAlt /></span>
                        <p>bipin@primedeveloper.group</p>
                    </div> */}
                </Container>
            </div>
            <div className='get-in-touch-map'>

                {/* <GoogleMapReact
                    bootstrapURLKeys={{ key: '' }}
                    defaultCenter={location}
                    defaultZoom={zoomLevel}
                >
                    <LocationPin
                        lat="37.42216"
                        lng="-122.08427"
                        text='1600 Amphitheatre Parkway, Mountain View, california.'
                    />
                </GoogleMapReact> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7338.513790689594!2d72.52288660479812!3d23.124283400000007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e83e462774a8b%3A0x657ec535a93c9ac4!2sPraangan!5e0!3m2!1sen!2sin!4v1667153898337!5m2!1sen!2sin" width="100%" height="100%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                {/* <iframe src="" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            </div>

        </div>
    )
}

export default GetInTouch