import React from 'react'
import praanganExp from '../../assets/Image/praangan-exp.jpg'
import './PraanganExp.scss'

function PraanganExp() {
  return (
    <div className='pe'>
        <div className="pe-wrapper">
            <div className="pe-left">
                <h1>Re-defining<br/>Experiences</h1>
                <img src={praanganExp} alt="" />
            </div>
            <div className="pe-right">
                <div className="pe-content">
                    <div className="pe-content-top">
                        <div className="pe-content-top-left">
                            <h2>04</h2>
                            <p>Towers</p>
                        </div>
                        <div className="pe-content-top-right">
                            <h2>38187</h2>
                            <p>sq. ft. open area</p>
                        </div>
                    </div>
                    <div className="pe-content-bottom">
                        <div className="pe-content-bottom-left">
                            <h2>
                                192
                            </h2>
                            <p>
                                Dwellings
                            </p>
                        </div>
                        <div className="pe-content-bottom-right">
                            <h2>23</h2>
                            <p>retail shops</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PraanganExp