import React from 'react'
import Footer from '../components/Footer/Footer'
import GreenFooter from '../components/Footer/GreenFooter'
import Header from '../components/Header/Header'
import PraanganAmenities from '../components/PraanganAmenities/PraanganAmenities'
import PraanganCarousel from '../components/PraanganCarousel/PraanganCarousel'
import PraanganExp from '../components/PraanganExp/PraanganExp'
import PraanganHero from '../components/PraanganHero/PraanganHero'
import PraanganInfo from '../components/PraanganInfo/PraanganInfo'
import PraanganRera from '../components/PraanganRera/PraanganRera'

function Praangan() {
  return (
    <div>
        <Header position={'absolute'}/>
        <PraanganHero/>
        <PraanganInfo/>
        <PraanganExp/>
        <PraanganRera/>
        <PraanganAmenities/>
        <PraanganCarousel/>
        <GreenFooter/>
        <Footer/>
    </div>
  )
}

export default Praangan