import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Container from '../Container/Container'
import bg1 from '../../assets/Image/PD_Website PNG (14).png'
import './BlogInnerContent.scss'

function BlogInnerContent(props) {
  return (
    <div className='bic'>
        <img className='bg1' src={bg1} alt="" />
        <Container>
            <Row>
                <Col xs={0} md={4}>
                </Col>

                <Col xs={12} md={8}>
                    <div className="bic__text">
                        
                        {props.contentOneParaOne.length ? 
                        <p>
                           {props.contentOneParaOne} 
                        </p>
                        : ''
                        }
                        
                        {props.contentOneParaTwo.length ? 
                        <p>
                           {props.contentOneParaTwo} 
                        </p>
                        : ''
                        }

                        {props.contentOneParaThree.length ? 
                        <p>
                           {props.contentOneParaThree} 
                        </p>
                        : ''
                        }
                    </div>
                </Col>              
            </Row>
        </Container>  
    </div>
  )
}

export default BlogInnerContent