import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop(props) {
  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // window.scrollY(0)
  }, [pathname]);

  return <>{props.children}</>;
}
