import React from 'react'
import Container from '../Container/Container'
import praanganLogo from '../../assets/Image/praangan-logo.png'
import './PraanganHero.scss'

function PraanganHero() {
  return (
    <div className='ph'>
        <div className="overlay"></div>
        <Container>
            <div className="ph-wrapper">
                <div className="ph-logo">
                    <img src={praanganLogo} alt="" />
                </div>
                <div className="ph-title">
                    <h2>Your Happiness Courtyard</h2>
                </div>
                <div className="ph-detail">
                    <h2>3BHK Elegant Dwellings and Retails</h2>
                </div>
                <div className="ph-address">
                    <p>
                        SG Highway, Chharodi, Ahmedabad
                    </p>
                </div>
                {/* <div className="ph-walk">
                    <p hre>
                        Praangan Walkthrough
                    </p>
                </div> */}
            </div>
        </Container>    
    </div>
  )
}

export default PraanganHero