
import React, { useEffect, useState, useRef } from 'react'
import Footer from '../components/Footer/Footer'
import GreenFooter from '../components/Footer/GreenFooter'
// import Header from '../components/Header/Header'
import Header from '../components/Header/Header'
import ComingSoon from '../components/Home/ComingSoon/ComingSoon'
import HomeAbout from '../components/Home/HomeAbout/HomeAbout'
import HomeBenchmark from '../components/Home/HomeBenchmark/HomeBenchmark'
import HomeHero from '../components/Home/HomeHero/HomeHero'
import HomeProject from '../components/Home/HomeProject/HomeProject'
import Testimonial from '../components/Home/Testimonial/Testimonial'
import {motion} from 'framer-motion'
import vdo from '../assets/Image/video.mp4'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import '../components/general.scss'
import { Helmet } from 'react-helmet'

gsap.registerPlugin(ScrollTrigger)

function Home() {

  const [vdoEnd, setVdoEnd] = useState()

  const videos= useRef();

  const setPlayBack = () => {
    // setPlaybackRate(e.target.value);
    videos.current.playbackRate = 5.0;
  };

  useEffect(()=> {
    ScrollTrigger.refresh(); 
  
    ScrollTrigger.getAll().forEach(t=>{
      setTimeout(()=>{
        t.disable();
        t.enable();
      }, 50)
    })

    return () => {
      ScrollTrigger.update();
      ScrollTrigger.refresh();

      // t1.kill();
      // ScrollTrigger.killAll();
      // ScrollTrigger.refresh();
      // console.log('kill');
      
    } 
  })

  return (
    <div
    >
      
        {/* <HomeHeroNew/> */}
        <motion.div 
        animate={{
          y: '-100%'
        }}
        transition={{delay: 4}}
        className="overlay-video">
          <video onCanPlay={() => setPlayBack()} ref={videos}  type="video/mp4" autoPlay muted>
            <source src={vdo}/>
          </video>
        </motion.div>
        <Header position={'absolute'}/>
        <HomeHero/>
        <HomeProject/>
        <HomeAbout/>
        <HomeBenchmark/>
        <ComingSoon/>
        <Testimonial/>
        <GreenFooter/>
        <Footer/>
    </div>
  )
}

export default Home