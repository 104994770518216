import React from 'react'
import { useParams } from 'react-router-dom'
import BlogInnerBanner from '../components/BlogInnerBanner/BlogInnerBanner'
import BlogInnerContent from '../components/BlogInnerContent/BlogInnerContent'
import BlogInnerContentTwo from '../components/BlogInnerContentTwo/BlogInnerContentTwo'
import BlogInnerHero from '../components/BlogInnerHero/BlogInnerHero'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import GreenFooter from '../components/Footer/GreenFooter'
import { BlogData } from '../components/Data'
import { useEffect, useState } from 'react'
import BlogInnerImages from '../components/BlogInnerImages/BlogInnerImages'

function BlogsInner() {

  const params = useParams();

  const [ blog, setBlog ]  = useState([]);

  useEffect(()=> {

    setBlog(BlogData)
  
  }, [params.id])
  

  return (

    <div>
        <Header position={'relative'}/>


      {BlogData.filter(data => data.id === params.id).map(filteredBlog => (
        <div>
        <BlogInnerHero 
        title={filteredBlog.title}
        intro={filteredBlog.intro}
        /> 
        <BlogInnerBanner banner={filteredBlog.banner}/>
        <BlogInnerContent
          contentOneParaOne={filteredBlog.contentOneParaOne}
          contentOneParaTwo={filteredBlog.contentOneParaTwo}
          contentOneParaThree={filteredBlog.contentOneParaThree}
        />

        <BlogInnerImages
          imageOne={filteredBlog.imageOne}
          imageTwo={filteredBlog.imageTwo}
        />

      <BlogInnerContentTwo
        titleMainOne = {filteredBlog.contentTwoMainTitleOne}
        titleMainTwo = {filteredBlog.contentTwoMainTitleTwo}
        titleMainThree = {filteredBlog.contentTwoMainTitleThree}
        titleMainContentThree = {filteredBlog.contentTwoMainParaThree}

        titleExtra={filteredBlog.titleExtra}
        contentExtraParaOne={filteredBlog.contentExtraParaOne}
        contentExtraParaTwo={filteredBlog.contentExtraParaTwo}
        contentExtraParaThree={filteredBlog.contentExtraParaThree}
        
        titleOne = {filteredBlog.contentTwoTitleOne}
        contentOne = {filteredBlog.contentTwoParaOne}

        titleTwo = {filteredBlog.contentTwoTitleTwo}
        contentTwo = {filteredBlog.contentTwoParaTwo}

        titleThree = {filteredBlog.contentTwoTitleThree}
        contentThree = {filteredBlog.contentTwoParaThree}

        titleFour = {filteredBlog.contentTwoTitleFour}
        contentFour = {filteredBlog.contentTwoParaFour}

        titleFive = {filteredBlog.contentTwoTitleFive}
        contentFive = {filteredBlog.contentTwoParaFive}

        titleSix = {filteredBlog.contentTwoTitleSix}
        contentSix = {filteredBlog.contentTwoParaSix}

        titleSeven = {filteredBlog.contentTwoTitleSeven}
        contentSeven = {filteredBlog.contentTwoParaSeven}

        titleEight = {filteredBlog.contentTwoTitleEight}
        contentEight = {filteredBlog.contentTwoParaEight}

        titleNine = {filteredBlog.contentTwoTitleNine}
        contentNine = {filteredBlog.contentTwoParaNine}

        titleTen = {filteredBlog.contentTwoTitleTen}
        contentTen = {filteredBlog.contentTwoParaTen}
      />

      </div>

        
      ))}
      <GreenFooter/>
      <Footer/>
    </div>
  )
}

export default BlogsInner