import React, { useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import logo from '../../assets/Image/logo-footer.png';
import './Popup.scss'
import emailjs from '@emailjs/browser';


function Popup(props) {
  const [show, setShow] = useState(false);
  const [notice, setNotice] = useState(false);

  const form = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showFile, setShowFile] = useState(false);

  const downloadFile = e => {
    e.preventDefault();
    setShowFile(true);

    emailjs.sendForm('service_yrhob8y', 'template_pvd9pwj', form.current, 'muNKqFlQ-WebRG5Fr')
    .then((result) => {
        console.log(result.text);
        setNotice(true)
    }, (error) => {
        console.log(error.text);
    });
  }

  return (
    <div className='popup'>
      <div className="button" onClick={handleShow}>Download Brochure</div>
        <Modal className='modal' show={show} onHide={handleClose}>
          <Modal.Header>
       
              <img src={logo} alt="" />
         
          </Modal.Header>
          <Modal.Body>
            <form ref={form} onSubmit={downloadFile}>

              {notice ? 
              <>
                <p className='success-notice'>Thank you for your response. Below is the download link for the brochure</p>
                <a type='submit' href={props.brochure} disabled target="_blank">Download Brochure</a>
              </> : <>
                <div className="popup-inputs">
                  <label htmlFor="name">Name</label>
                  <input required type="text" name="from_name" placeholder='Name'/>
                </div>

                <div className="popup-inputs">
                  <label htmlFor="email">E-mail</label>
                  <input required type="email" name="email" placeholder='Email'/>
                </div>

                <div className="submit-btn">
                  <button type='submit'>Submit
                  </button>
                  
                </div>
                <div className="notice">
                  <p>*Submit the form to download the Brochure</p>
                </div>
              </>}
              
              
            </form>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
        </Modal>
    </div>
  )
}

export default Popup