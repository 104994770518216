import React, {useEffect} from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import GreenFooter from '../components/Footer/GreenFooter'
import Herosection from '../components/LoftGardens/HeroSection/HeroSection'
import Banner from '../components/LoftGardens/BannerSection/Banner'
import StunningSection from '../components/LoftGardens/StunningSection/StunningSection'
import GallerySection from '../components/LoftGardens/GallerySection/GallerySection'
import ScrollTrigger from 'gsap/ScrollTrigger'

function Projects() {
  useEffect(()=> {
    ScrollTrigger.refresh(); 
  
    ScrollTrigger.getAll().forEach(t=>{
      setTimeout(()=>{
        t.disable();
        t.enable();
      }, 50)
    })

    return () => {
      ScrollTrigger.update();
      ScrollTrigger.refresh();

      // t1.kill();
      // ScrollTrigger.killAll();
      // ScrollTrigger.refresh();
      // console.log('kill');
      
    } 
  })

  return (
    <div>
        <Header/>
        <Herosection/>
        <GallerySection/>
        <StunningSection/>
        <Banner/>
        <GreenFooter/>
        <Footer/>
    </div>
  )
}

export default Projects