import React from 'react'
import './Header.scss'
import { Nav, Container, Navbar, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../assets/Image/logo.png';
import img from '../../assets/Image/about-pattern2.png'
import Dropdown from 'react-bootstrap/Dropdown';

function Header(props) {
  return (
    <div className='header' style={{position: `${props.position}`}}>
      
        <div className='header-logo'>
          <div>
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>

        </div>
        <div className='header-container'>
          <hr />
          <div className='header-container-content'>
            <div className='header-container-content-left'>

              <p>
                 <Link to="/contact-us">We build great experiences. <br />Looking for a home you deserve? let's talk. <span className='link-hover'>&rarr;</span></Link> 
              </p>
            </div>
            <div className='header-container-content-right'>
              <Navbar key="lg" expand="false" className="mb-3">
                <Container fluid id='container'>
                  <Navbar.Toggle id="toggle" aria-controls={`offcanvasNavbar-expand-lg`} />
                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-lg`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                    placement="end"
                  >
                    <Offcanvas.Header className='close' closeButton closeVariant='white'>
                      {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                        Offcanvas
                      </Offcanvas.Title> */}
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav className="justify-content-end flex-grow-1 pe-3">
                        <Link className='nav-a' to="/">  <Nav.Link  className='nav-a'  href="#action1">Home</Nav.Link></Link>
                        
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <div className='nav-a'><Nav.Link  className='nav-a'>Projects</Nav.Link></div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <Link className='nav-a' to="/loft-gardens"><Nav.Link  className='nav-a' href="#action2">Loft Gardens</Nav.Link></Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link className='nav-a' to="/prime-status"><Nav.Link  className='nav-a' href="#action2">Prime Status</Nav.Link></Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link className='nav-a' to="/praangan"><Nav.Link  className='nav-a' href="#action2">Praangan</Nav.Link></Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                      
                        </Dropdown> 
                        
                        <Link className='nav-a' to="/about-us"><Nav.Link  className='nav-a' href="#action2">Prime Story</Nav.Link></Link>
                        <Link className='nav-a' to="/blogs"><Nav.Link className='nav-a'  href="#action2">Blogs</Nav.Link> </Link>
                        <Link className='nav-a' to="/contact-us"><Nav.Link className='nav-a'  href="#action2">Connect</Nav.Link> </Link>
                      </Nav>
                      <div className="bg-img-wrapper">
                        <img src={img} alt="" />

                      </div>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Container>
              </Navbar>
            </div>

          </div>
        </div>
    </div>
  )
}

export default Header