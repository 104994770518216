import React, {useEffect} from 'react'
import HeroSection from '../components/About/HeroSection/HeroSection'
import LogoPhilosophy from '../components/About/LogoPhilosophy/LogoPhilosophy'
import OurGoals from '../components/About/OurGoals/OurGoals'
import Primedeveloper from '../components/About/PrimeDeveloper/Primedeveloper'
import Success from '../components/About/Success/Success'
import VisionMision from '../components/About/Vision/VisionMision'
import Footer from '../components/Footer/Footer'
import GreenFooter from '../components/Footer/GreenFooter'
import Header from '../components/Header/Header'
import ScrollTrigger from 'gsap/ScrollTrigger'
import {motion} from 'framer-motion'

function About() {
  useEffect(()=> {
    ScrollTrigger.refresh(); 
  
    ScrollTrigger.getAll().forEach(t=>{
      setTimeout(()=>{
        t.disable();
        t.enable();
      }, 50)
    })

    return () => {
      ScrollTrigger.update();
      ScrollTrigger.refresh();

      // t1.kill();
      // ScrollTrigger.killAll();
      // ScrollTrigger.refresh();
      // console.log('kill');
      
    } 
  })
  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
    >
      
      <Header position={'relative'}/>
      <HeroSection/>
      <VisionMision />
      <Primedeveloper/>
      <LogoPhilosophy/>
      <OurGoals/>
      <Success/>
      <GreenFooter/>
      <Footer/>
      </motion.div>
  )
}

export default About