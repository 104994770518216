import React from 'react'
import "./Banner.scss"
function Banner() {
  return (
    <div className='banner'>
       <iframe width="100%" height="100%" src="https://www.youtube.com/embed/FQuAAeSdRUo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    // <iframe width="560" height="315" src="https://www.youtube.com/embed/FQuAAeSdRUo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  )
}

export default Banner