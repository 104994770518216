import { React, useRef, useEffect } from 'react'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from 'gsap';
import "./Ourgoal.scss"
import Container from '../../Container/Container'
import Heading from '../../Heading/Heading'
import aboutImg from '../../../assets/Image/about-img.jpg'

function OurGoals() {
    gsap.registerPlugin(ScrollTrigger);
    
    return (
        <div className='our-goals'>
            <Container>
                <Heading>OUR GOALS</Heading>
                
                <div className='our-goals-content'>
                    <div className='our-goals-content-left'>
                        <div></div>
                    </div>
                    <div className='our-goals-content-middle'>
                        <div className='our-goals-content-middle-1  star'>
                            <span>01</span>
                            <div>
                                <h1>CONNECTED</h1>
                                <p>Relationships are vital to us; we help individuals stay connected to their communities and homes.</p>
                            </div>
                        </div>
                        <div className='our-goals-content-middle-2  star'>
                            <span>02</span>
                            <div>
                                <h1>UPSTANDING</h1>
                                <p>
                                    Everything we do is centered on serving the needs and best interests of our clients.
                                </p>
                            </div>
                        </div>
                        <div className='our-goals-content-middle-3  star'>
                            <span>03</span>
                            <div>
                                <h1>INTEGRITY</h1>
                                <p>
                                    To ensure the success of everyone involved, we commit to constantly acting in the best interests of our clients, team, and company.

                                </p>
                            </div>
                        </div>
                        <div className='our-goals-content-middle-4  star'>
                            <span>04</span>
                            <div>
                                <h1>REDEFINING EXPERIENCES</h1>
                                <p>
                                    With innovation & embracing technology we focus on solutions to provide one-of-a-kind living experiences for our customers.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='our-goals-content-right'>
                        <img src={aboutImg} alt="" />
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default OurGoals