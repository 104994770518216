import React, { useEffect } from 'react'
import FormSection from '../components/Contact/FormSection/FormSection'
import GetInTouch from '../components/Contact/GetInTouch/GetInTouch'
import HeroSection from '../components/Contact/HeroSection/HeroSection'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import ScrollToTop from '../components/ScrollToTop'

function Contact() {
  useEffect(()=>{
    window.scrollTo(0, 0);
    <ScrollToTop/>
  }, [])
  return (
    <div>
      <ScrollToTop/>
      <Header position={'relative'}/>

      <HeroSection/>
      <FormSection/>
      <GetInTouch/>
      <Footer/>
    </div>
  )
}

export default Contact