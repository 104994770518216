import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import GreenFooter from '../components/Footer/GreenFooter'
import Herosection from '../components/PrimeStatus/HeroSection/HeroSection'
import Banner from '../components/PrimeStatus/BannerSection/Banner'
import StunningSection from '../components/PrimeStatus/StunningSection/StunningSection'
import GallerySection from '../components/PrimeStatus/GallerySection/GallerySection'

function PrimeStatus() {
  return (
    <div>
        <Header position={'relative'}/>
        <Herosection/>
        <GallerySection/>
        <StunningSection/>
        <Banner/>
        <GreenFooter/>
        <Footer/>
    </div>
  )
}

export default PrimeStatus