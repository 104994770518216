import {React, useEffect} from 'react'
import Container from '../../Container/Container'
import projectImg from '../../../assets/Image/prime-status-logo.png';
import gsap from 'gsap'

import "./HeroSection.scss"

function HeroSection(props) {

    useEffect(()=> {

        var t1 = gsap.timeline();

        t1.
        from('.pjt-hero-section-left-top', {
            opacity: 0
        }, 0.2)
        .from('.pjt-hero-section-left-middle div p', {
            opacity: 0,
            y: '20px',
            stagger: 0.2
        }, 0.5)
        .from('.pjt-hero-section-right-top h1 span', {  
            opacity: 0,
            // y: '20px',
            stagger: 0.04
        }, 0.5)
        .from('.pjt-hero-section-left-bottom h2', {
            opacity: 0,
            y:'20px'
        }, 1)
    }, [])
    
    return (
        <div className='pjt-hero-section'>

            <Container className="pjt-hero-section-left">
                <div className="pjt-hero-section-left-top">
                    <img src={projectImg} alt="" />
                </div>
                <div className="pjt-hero-section-left-middle">
                    <div>
                        <p>3BHK</p>
                        <p>76 Units</p>
                    </div>
                    <div>
                        <p>PENTHOUSE </p>
                        <p>04 Units</p>
                    </div>
                    {/* <div>
                        <p>PENTHOUSE</p>
                        <p>04 Units</p>
                    </div> */}
                    <div>
                        <p>RETAIL </p>
                        <p>14 Units</p>
                    </div>
                </div>
                <div className="pjt-hero-section-left-bottom">
                    <h2>RE-DEFINING
                        EXPERIENCES.</h2>
                </div>

            </Container>
            <div className="pjt-hero-section-right">
                <div className="pjt-hero-section-right-top">
                    <h1>
                        <span>T</span>
                        <span>H</span>
                        <span>E</span>
                        <span> A</span>
                        <span>B</span>
                        <span>O</span>
                        <span>D</span>
                        <span>E </span>
                        <span>O</span>
                        <span>F <br/></span>
                        <span>L</span>
                        <span>U</span>
                        <span>X</span>
                        <span>U</span>
                        <span>R</span>
                        <span>I</span>
                        <span>O</span>
                        <span>U</span>
                        <span>S </span>
                        
                        <span>P</span>
                        <span>E</span>
                        <span>R</span>
                        <span>F</span>
                        <span>E</span>
                        <span>C</span>
                        <span>T</span>
                        <span>I</span>
                        <span>O</span>
                        <span>N</span>
                        
                        </h1>
                </div>
                <div className="pjt-hero-section-right-bottom">
                    {/* <div className="abs-overlay"></div>  */}
                </div>
            </div>

        </div>
    )
}

export default HeroSection