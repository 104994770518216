import {React, useEffect } from 'react'
import Container from '../../Container/Container'
import projectImg from '../../../assets/Image/project-img.png';
import bgUrl from '../../../assets/Image/loft-hero.jpg'
import gsap from 'gsap'
import "./HeroSection.scss"

function HeroSection(props) {
    

    useEffect(()=> {

        var t1 = gsap.timeline();

        t1.
        from('.pjt-hero-section-left-top', {
            opacity: 0
        }, 0.2)
        .from('.pjt-hero-section-left-middle div p', {
            opacity: 0,
            y: '20px',
            stagger: 0.2
        }, 0.5)
        .from('.pjt-hero-section-right-top h1 span', {  
            opacity: 0,
            // y: '20px',
            stagger: 0.04
        }, 0.5)
        .from('.pjt-hero-section-left-bottom h2', {
            opacity: 0,
            y:'20px'
        }, 1)

    }, [])

    return (
        <div className='loft-gardens'>
            <div className='pjt-hero-section'>

                <Container className="pjt-hero-section-left">
                    <div className="pjt-hero-section-left-top">
                        <img src={projectImg} alt="" />
                    </div>
                    <div className="pjt-hero-section-left-middle">
                        <div>
                            <p>4BHK</p>
                            <p>42 Units</p>
                        </div>
                        <div>
                            <p>3BHK </p>
                            <p>44 Units</p>
                        </div>
                        <div>
                            <p>PENTHOUSE</p>
                            <p>04 Units</p>
                        </div>
                        <div>
                            <p>RETAIL </p>
                            <p>18 Units</p>
                        </div>
                    </div>
                    <div className="pjt-hero-section-left-bottom">
                        <h2>
                            RE-DEFINING
                            EXPERIENCES.
                        </h2>
                    </div>

                </Container>
                <div className="pjt-hero-section-right">
                    <div className="pjt-hero-section-right-top">
                        <h1>
                            <span>I</span>
                            <span>N</span>
                            <span>T</span>
                            <span>R</span>
                            <span>O</span>
                            <span>D</span>
                            <span>U</span>
                            <span>C</span>
                            <span>I</span>
                            <span>N</span>
                            <span>G </span>
                            <span>A<br/></span>
                            <span> G</span>
                            <span>R</span>
                            <span>E</span>
                            <span>E</span>
                            <span>N </span>
                            <span>W</span>
                            <span>A</span>
                            <span>Y </span>
                            <span>O</span>
                            <span>F </span>
                            <span>L</span>
                            <span>I</span>
                            <span>V</span>
                            <span>N</span>
                            <span>G</span>
                      
                        </h1>
                    </div>
                    <div className="pjt-hero-section-right-bottom" style={{backgroundImage: `url(${bgUrl})`}}>
                        {/* <div className="abs-overlay"></div> */}
                    </div>
                </div>

            </div>

    </div>
    )
}

export default HeroSection