import {React,useRef,useEffect} from 'react'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import  gsap  from 'gsap';
import  Container  from '../../../components/Container/Container'
import "./Visionmission.scss"
import vision from '../../../assets/Image/vision.png';
import mission from '../../../assets/Image/mission.png';

function VisionMision() {

    gsap.registerPlugin(ScrollTrigger);
    const parent = useRef();

    useEffect(()=> {
        const t1 = gsap.timeline({
            scrollTrigger: {
              trigger: parent.current,
              scrub: false,
              // markers: true,
              start: `top ${(window.innerHeight) * 2/4}`,
              end: 'bottom bottom'
            },
          });


          t1.from(".vision-mission-content1 span", {
            opacity: 0,
            y: '20px'
          },0.4 )
          .from(".vision-mission-content1 h1", {
            opacity: 0,
            y: '20px'
          }, 0.5)
          .from(".vision-mission-content1 p", {
            opacity: 0,
            y: '20px',
            stagger: '0.04'
          }, 0.5)
          
    }, [])
    
  return (
    <div className='vision-mission' ref={parent}>
 
        <Container>
            <div className='vision-mission-content1 content1'>
                <span>
                    <img src={vision} alt="" />
                </span>
                <h1>OUR VISION</h1>

                <p className='pre'>
                    Loyal and resilient. Implementing tech towards 
                    future for better living standards.
                </p>

                <p>
                    We aspire to continually push the limits of
                    great finishing and craftsmanship.
                </p>
                
                 <p>
                    We focus on offering you a better living,
                    we strive for continual progress in order
                    to establish ourselves as the most
                    trustworthy and reliable brand in real
                    estate while establishing the benchmark
                    of quality.
                </p>
            </div>


            <div className='vision-mission-content1 content2'>
                <span>
                    <img src={mission} alt="" />
                </span>
                <h1>OUR MISSION</h1>
                <p className="pre">You know you are at Prime when you get more than what you asked for.</p>
                <p>We build designs that improve
                    neighborhoods by utilizing high-quality
                    materials that provide homes a longer
                    and more sustainable life.
                    </p>
                    <p>
                    Our customer-oriented designs are
                    functional and are implemented utilizing
                    cutting-edge technology to enable
                    hassle-free living.</p>
            </div>
        </Container>

    </div>
  )
}

export default VisionMision