import {React,useRef,useEffect} from 'react'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import  gsap  from 'gsap';
import Container from '../../Container/Container'
import Heading from '../../Heading/Heading'
import "./Homebenchmark.scss"
import quality from "../../../assets/Image/quality.png";
// import trust from "../../../assets/Image/trust.png";
import commintment from "../../../assets/Image/commintment.png";
import excellence from "../../../assets/Image/excellence.png";
import trust from '../../../assets/Image/SVG/trust.svg'
import logo from '../../../assets/Image/logo.png';

gsap.registerPlugin(ScrollTrigger);

function HomeBenchmark() {

    const head = useRef();
    const content = useRef();
    const image = useRef();
    const parent = useRef();
    const overlay = useRef();

    useEffect(() => { 

        // ScrollTrigger.refresh(); 

        const t1 = gsap.timeline({
            scrollTrigger: {
              trigger: parent.current,
              scrub: false,
              // markers: true,
              start: `top ${(window.innerHeight) * 2/4}`,
              end: 'bottom bottom'
            },
          });

          t1.from('.home-benchmark-title div span', {
            opacity: 0,
            y: '20px',
            stagger: 0.04,
          })
          .from(content.current, {
            opacity: 0,
            // y: '20px'
          }, 0.5)
          .to(overlay.current, {
            x: '100%'
          }, 0.5)

        //   return () => {
        //     ScrollTrigger.kill();
        //   }
    }, [])
    return (
        <div className='home-benchmark' ref={parent}>
            <Container>
                <div className="home-benchmark-title">
                    <Heading>
                        <div ref={head}>
                            <span>O</span>
                            <span>U</span>
                            <span>R</span>
                            <span> </span>
                            <span>V</span>
                            <span>I</span>
                            <span>R</span>
                            <span>T</span>
                            <span>U</span>
                            <span>E</span>
                            <span>S</span>
                        </div>
                    </Heading>
                </div>
                <div className="home-benchmark-content" >
                    <div className="home-benchmark-content-left" ref={content}> 

                        <div className="home-benchmark-content-left-content">

                            <div className="home-benchmark-content-left-content-item1">
                                <div className="home-benchmark-content-left-content-item1-box1">
                                    <div>
                                        <img src={quality} alt="" />
                                    </div>
                                    <h4>Quality</h4>
                                    <p>Using the highest quality materials to ensure the
                                        longevity of your homes.</p>
                                </div>
                                <div className="home-benchmark-content-left-content-item1-box2">
                                    <div>
                                        <img src={trust} alt="" />
                                    </div>
                                    <h4>Trust</h4>
                                    <p>Best use of the latest technology to provide a
                                        hassle-free lifestyle.</p>
                                </div>
                            </div>
                            <div className="home-benchmark-content-left-content-item2">
                                <div className="home-benchmark-content-left-content-item1-box1">
                                    <div>
                                        <img src={commintment} alt="" />

                                    </div>
                                    <h4>Commitment</h4>
                                    <p>Living the commitment, delivering the promises.
                                        With us, you always get more than you asked for.</p>
                                </div>
                                <div className="home-benchmark-content-left-content-item1-box2">
                                    <div>
                                        <img src={excellence} alt="" />

                                    </div>
                                    <h4>Excellence</h4>
                                    <p>Our proven track record of delivery paired with
                                        innovative architecture has helped us gain the
                                        distinction of developer-par-excellence.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="home-benchmark-content-right" useRef={image}>
                        <div className="home-benchmark-overlay" ref={overlay}></div>
                        <div className="home-benchmark-content-right-abs">
                            <img src={logo} alt="" />
                        </div>
                    </div>
                </div>

            </Container>

        </div>
    )
}

export default HomeBenchmark