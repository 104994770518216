import {React, useRef, useEffect} from 'react'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import  gsap  from 'gsap';
import "./HeroSection.scss"
import circle from "../../../assets/Image/circle.png"
function HeroSection() {

    useEffect(()=> {    
        ScrollTrigger.refresh(); 

        const t1 = gsap.timeline();

        t1.from('.abt-hero-section-right h1 span', {
            opacity: 0,
            stagger: 0.04
        })
        
    }, [])

    return (
        <div className="abt-hero-section">
            <div className="abt-hero-section-left">

                <div className="abt-hero-section-left-content">

                </div>
            </div>
            <div className="abt-hero-section-right">
                <h1>
                    <span>R</span>
                    <span>E</span>
                    <span className='cercle'>
                        <img src={circle} alt="" />
                    </span>
                    <span>D</span>
                    <span>E</span>
                    <span>F</span>
                    <span>I</span>
                    <span>N</span>
                    <span>I</span>
                    <span>N</span>
                    <span>G</span>
                    <span><br/></span>
                    <span>E</span>
                    <span>X</span>
                    <span>P</span>
                    <span>E</span>
                    <span>R</span>
                    <span>I</span>
                    <span>E</span>
                    <span>N</span>
                    <span>C</span>
                    <span>E</span>
                    <span>S</span>
                </h1>
            </div>
        </div>
    )
}

export default HeroSection