import React from 'react'
import Container from '../Container/Container'
import {Row, Col} from 'react-bootstrap'
import blogInner from '../../assets/Image/blog-inner.png'
import './BlogsMain.scss'
import { Link } from 'react-router-dom'
import blog1 from '../../assets/Image/The Must-Have Amenities in 2022 A Guide On How To Pick Up Flats_2.jpg'
import blog2 from '../../assets/Image/The 10 Best Things To Keep In Mind While Looking For A New House.jpg'
import blog3 from '../../assets/Image/The Emerging Trends in Residential Real Estate.jpg'
import blog4 from '../../assets/Image/Does The importance of open area in a city space Really Live up to the Hype.jpg'
import blog5 from '../../assets/Image/Blogs/blog5banner.jpg'
import blog6 from '../../assets/Image/Blogs/blog6banner.jpg'

function BlogsMain() {
  return (
    <div className='blogs-main'>
        <Container>
        <div className="blogs-main-wrapper">
            <Row>
                <Col xs={6}>
                    <Link to="/blogs/1">
                        <div className="blog-card">
                            <div className="blog-image">
                                <img src={blog1} alt="" />
                            </div>
                            <div className="blog-content">
                                <div className="blog-caption">
                                    <p>
                                        Jan 24, 2022
                                    </p>
                                </div>
                                <div className="blog-title">
                                    <h4>
                                        Importance of Open Area in city space
                                    </h4>
                                </div>
                                {/* <div className="blog-title-text">
                                    <p>
                                        On the other hand, we denounce with righteous indignation and
                                        dislike men who are so beguiled.
                                    </p>
                                </div> */}
                            </div>   
                        </div>
                    </Link>
                </Col>

                <Col xs={6}>
                <Link to="/blogs/2">
                        <div className="blog-card">
                            <div className="blog-image">
                                <img src={blog2} alt="" />
                            </div>
                            <div className="blog-content">
                                <div className="blog-caption">
                                    <p>
                                        Jan 24, 2022
                                    </p>
                                </div>
                                <div className="blog-title">
                                    <h4>
                                        The Emerging Trends in Residential Real Estate
                                    </h4>
                                </div>
                                {/* <div className="blog-title-text">
                                    <p>
                                        On the other hand, we denounce with righteous indignation and
                                        dislike men who are so beguiled.
                                    </p>
                                </div> */}
                            </div>   
                        </div>
                    </Link>
                </Col>
            </Row>

            <Row>
                <Col xs={6}>
                    <Link to="/blogs/3">
                        <div className="blog-card">
                            <div className="blog-image">
                                <img src={blog3} alt="" />
                            </div>
                            <div className="blog-content">
                                <div className="blog-caption">
                                    <p>
                                        Jan 24, 2022
                                    </p>
                                </div>
                                <div className="blog-title">
                                    <h4>
                                        Things to keep in mind while looking for a house
                                    </h4>
                                </div>
                                {/* <div className="blog-title-text">
                                    <p>
                                        On the other hand, we denounce with righteous indignation and
                                        dislike men who are so beguiled.
                                    </p>
                                </div> */}
                            </div>   
                        </div>
                    </Link>
                </Col>

                <Col xs={6}>
                <Link to="/blogs/4">
                        <div className="blog-card">
                            <div className="blog-image">
                                <img src={blog4} alt="" />
                            </div>
                            <div className="blog-content">
                                <div className="blog-caption">
                                    <p>
                                        Jan 24, 2022
                                    </p>
                                </div>
                                <div className="blog-title">
                                    <h4>
                                        The must have amenities in 2022
                                    </h4>
                                </div>
                                {/* <div className="blog-title-text">
                                    <p>
                                        On the other hand, we denounce with righteous indignation and
                                        dislike men who are so beguiled.
                                    </p>`
                                </div> */}
                            </div>   
                        </div>
                    </Link>
                </Col>
            </Row>

            <Row>
                <Col xs={6}>
                    <Link to="/blogs/5">
                        <div className="blog-card">
                            <div className="blog-image">
                                <img src={blog5} alt="" />
                            </div>
                            <div className="blog-content">
                                <div className="blog-caption">
                                    <p>
                                        Jan 24, 2022
                                    </p>
                                </div>
                                <div className="blog-title">
                                    <h4>
                                        How to Make the Most of Your First Time Home Buying Experience in India
                                    </h4>
                                </div>
                                {/* <div className="blog-title-text">
                                    <p>
                                        On the other hand, we denounce with righteous indignation and
                                        dislike men who are so beguiled.
                                    </p>
                                </div> */}
                            </div>   
                        </div>
                    </Link>
                </Col>

                <Col xs={6}>
                <Link to="/blogs/6">
                        <div className="blog-card">
                            <div className="blog-image">
                                <img src={blog6} alt="" />
                            </div>
                            <div className="blog-content">
                                <div className="blog-caption">
                                    <p>
                                        Jan 24, 2022
                                    </p>
                                </div>
                                <div className="blog-title">
                                    <h4>
                                    Female Homeowners: Why Buying A Home Is One Of The Best Decisions You'll Ever Make
                                    </h4>
                                </div>
                                {/* <div className="blog-title-text">
                                    <p>
                                        On the other hand, we denounce with righteous indignation and
                                        dislike men who are so beguiled.
                                    </p>
                                </div> */}
                            </div>   
                        </div>
                    </Link>
                </Col>
            </Row>
        </div>
        </Container>
    </div>
  )
}

export default BlogsMain