import {React,useRef,useEffect} from 'react'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import  gsap  from 'gsap';
import Container from '../../Container/Container'
import Heading from '../../Heading/Heading'
import "./Primedeveloper.scss"
function Primedeveloper() {
    
    gsap.registerPlugin(ScrollTrigger);
    const parent = useRef();

    useEffect(() => {

        const t1 = gsap.timeline({
            scrollTrigger: {
              trigger: parent.current,
              scrub: false,
              // markers: true,
              start: `top ${(window.innerHeight) * 2/4}`,
              end: 'bottom bottom'
            },
          });

          t1.from('.Prime-developer-left h1 span', {
            opacity: 0,
            stagger: 0.04
          }).from('.Prime-developer-left p', {
            opacity: 0,
            y: '20px'
          }, 0.5)

    }, [])

    return (
        <div className='Prime-developer' ref={parent}>

            <Container>
                <div className='Prime-developer-left'>
                    <Heading>
                         <span>P</span>
                         <span>R</span>
                         <span>I</span>
                         <span>M</span>
                         <span>E</span>
                         <span> </span>
                         <span>G</span>
                         <span>R</span>
                         <span>O</span>
                         <span>U</span>
                         <span>P</span>
                            
                    </Heading>
                    <p>Prime wants to build beautiful homes
                        that connect people with their spaces
                        meaningfully and comfortably.</p>
                </div>
                <div className='Prime-developer-right'>
                
            </div>
            </Container>
        </div>
    )
}

export default Primedeveloper