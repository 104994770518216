import { React, useRef, useEffect } from 'react'

import { ScrollTrigger } from "gsap/ScrollTrigger";
import  gsap  from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import { CSSRulePlugin } from 'gsap/all';
import "./Homeproject.scss";

import Container from '../../Container/Container'
import Heading from '../../Heading/Heading'

import { Link } from 'react-router-dom';

import img1 from '../../../assets/Image/home1.png';
import img2 from '../../../assets/Image/home2.png';
import primeLogo from '../../../assets/Image/SVG/prime-status.png';
import projectImg from '../../../assets/Image/SVG/loft.png';

// gsap.registerPlugin( ScrollTrigger );

function HomeProject() {  

  gsap.registerPlugin(CSSRulePlugin)

  const logoImgOne = useRef();
  const logoImgTwo = useRef();
  const transOne = useRef();
  const transTwo = useRef();
  const parent = useRef();
  const head = useRef();
  const captionOne = useRef();
  const titleOne = useRef();
  const textOne = useRef();
  const captionTwo = useRef();
  const titleTwo= useRef();
  const textTwo= useRef();
  const boxOne= useRef();
  const boxTwo= useRef();

  
  useEffect(() => {
    // gsap.registerPlugin( ScrollTrigger )

    ScrollTrigger.refresh(); 
    const el = parent.current;
    const ruleOne = CSSRulePlugin.getRule('.home-project-content-1::before')
    const ruleTwo = CSSRulePlugin.getRule('.home-project-content-1::after')
    const ruleThree = CSSRulePlugin.getRule('.home-project-content-2::after')
  
    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: parent.current,
        scrub: false,
        // markers: true,
        start: `top ${(window.innerHeight) * 2/4}`,
        end: 'bottom bottom'
      },
    });
    
    t1
    .from('.home-project .h1-spans span', {
      opacity: 0,
      y: '20px',
      stagger: 0.04,
      
    }, 0)
    .from(ruleOne, {
      height: '0%',
    }, 0)
    .from(ruleTwo, {
      height: '0%'
    }, 0)
    .from(ruleThree, {
      height: '0%'
    }, 0)
    .fromTo( transOne.current, {
      x: '0%',
      ease: "expo.out",
    }, {
      x: '100%'
    }, 0.5)
    .fromTo(transTwo.current, {
      x: '0%',
      ease: "expo.out",
    }, {
      x: '100%'
    }, 0.5)
    .from(logoImgOne.current, {
      scale: '1.2'
    }, 0.5).from(logoImgTwo.current, {
      scale: '1.2'
    }, 0.5)
    .from(captionOne.current, {
      opacity: 0
    }, 1)
    .from(titleOne.current, {
      opacity: 0
    }, 1)
    .from(textOne.current, {
      opacity: 0
    }, 1)
    .from(captionTwo.current, {
      opacity: 0
    }, 1)
    .from(titleTwo.current, {
      opacity: 0
    }, 1)
    .from(textTwo.current, {
      opacity: 0
    }, 1)

  }, [])


    
  return (

    <div className="home-project" ref={parent}>
      <Container>
        <div className="home-project-title">

          <Heading className="h1">
            <div ref={head} className="h1-spans">
              <span>C</span>
              <span>O</span>
              <span>M</span>
              <span>P</span>
              <span>L</span>
              <span>E</span>
              <span>T</span>
              <span>E</span>
              <span>D</span>
              <span> </span>
              <span>P</span>
              <span>R</span>
              <span>O</span>
              <span>J</span>
              <span>E</span>
              <span>C</span>
              <span>T</span>
              <span>S</span>
              
            </div>
          </Heading>
        </div>
        <div className="home-project-content">

          <div className='home-project-content-1' ref={boxOne}>
            <a href='/loft-gardens'> 
              <div className='img-parent'>
                <div className="trans-img trans-img-one" ref={transOne}></div>
                <div className="logo-img">
                  <img src={projectImg} alt="" />
                </div>
                <img rel="preload" src={img1} alt="" ref={logoImgOne}/>
              </div>
            </a>
            <p ref={captionOne} className="caption">Loft Gardens</p>
            <h3 ref={titleOne} className="title">Your home in the
              midst of nature</h3>
            <a href="/loft-gardens">
              <div ref={textOne}>
                Explore More&nbsp; <span className='link-hover'>&rarr;</span> 
              </div>
            </a>
          </div>

          <div className='home-project-content-2' ref={boxTwo}>
            <a href="/prime-status">
              <div className='img-parent'>
                <div className="trans-img trans-img-one" ref={transTwo}></div>

                <div className="logo-img">
                  <img src={primeLogo} alt="" />
                </div>
                {/* <img src={img1} alt="" /> */}
                <img src={img2} alt="" ref={logoImgTwo}/>
              </div>
            </a> 

            <p ref={captionTwo}>Prime Status</p>

            <h3 ref={titleTwo}>Practically designed
              for a better living</h3>

            <a href="/prime-status">
              <div ref={textTwo}>
              Explore More&nbsp; <span className='link-hover'>&rarr;</span>
              </div>
            </a>
          </div>
        </div>
      </Container>
    </div>

  )
}

export default HomeProject