import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import slideOne from '../../assets/Image/pa-slide-one.jpg'
import slideTwo from '../../assets/Image/praangan-slide-1.jpg'
import './PraanganAmenities.scss'

function PraanganAmenities() {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

  return (
    <div className='pa'>
        <div className="pa-wrapper">
            <div className="pa-left">
                <h1>Amenities</h1>
                <ul>
                    <li>Solar energy for common areas</li>
                    <li>Large PRAANGAN on each floor of the building</li>
                    <li>Multipurpose Hall and Court</li>
                    <li>Mini theatre and kids play area</li>
                    <li>Lily pond with and Sunken sittings</li>
                    <li>Library, GYM, Indoor games</li>
                    <li>Gazebo and Toddlers area</li>
                    <li>Power backup and Fire hydrant sytems</li>
                </ul>
            </div>
            <div className="pa-right">
                {/* <h1>Layouts</h1> */}
                <Carousel infinite={true} responsive={responsive} arrows={false} showDots={true} autoPlay={true}>
                    <div className="slide-one">
                        <img src={slideOne} alt="" />
                    </div>
                    <div className="slide-two">
                        <img src={slideTwo} alt="" />
                    </div>
                </Carousel>

                {/* <img src={slideOne} alt="" /> */}
            </div>
        </div>
    </div>
  )
}

export default PraanganAmenities