import { React, useRef, useEffect } from 'react'
import Heading from '../../Heading/Heading'
import gsap from 'gsap'
import "./StunningSection.scss"
import one from '../../../assets/Image/1.png';
import two from '../../../assets/Image/2.png';
import three from '../../../assets/Image/3.png';
import four from '../../../assets/Image/4.png';
import five from '../../../assets/Image/5.png';
import six from '../../../assets/Image/6.png';

function StunningSection() {
    
    const parent = useRef();

    useEffect(()=> {
        const t1 = gsap.timeline({
            scrollTrigger: {
              trigger: parent.current,
              scrub: false,
              // markers: true,
              start: `top ${(window.innerHeight) * 2/4}`,
              end: 'bottom bottom'
            },
          });

        t1.from('.stunning-section-middle', {
            opacity: 0,
            y:'20px'
        })
        .from('.stunning-section-middle-container img', {
            opacity: 0,
            y: '20px',
            stagger: 0.09
        }, 0.5)
        .from('.stunning-section-middle-container h1', {
            opacity: 0,
            y: '20px',
            stagger: 0.09
        }, 0.5)
    }, [])

    return (
        <div className="stunning-section">

            <div className='stunning-section-left'>
                <div></div>
            </div>
            <div className='stunning-section-middle'>
                <Heading>Stunning Luxury Amenities,<br/> Designed For Life</Heading>
                <div className='stunning-section-middle-container'>
                    <div><span><img src={one} alt="" /></span> <h1>24 Hour water Supply</h1></div>
                    <div><span><img src={two} alt="" /></span> <h1>CCTV Surveillance</h1></div>
                    <div><span><img src={three} alt="" /></span> <h1>Kids Play Area </h1></div>
                </div>
                <div className='stunning-section-middle-container'>
                    <div><span><img src={four} alt="" /></span> <h1>Basement Parking</h1></div>
                    <div><span><img src={five} alt="" /></span> <h1>Round the clock security</h1></div>
                    <div><span><img src={six} alt="" /></span> <h1>Gym</h1></div>
                </div>
            </div>

            <div className='stunning-section-right'>
                <div></div>
            </div>

        </div>
    )
}

export default StunningSection