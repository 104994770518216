import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import ReraContent from '../components/ReraContent/ReraContent'

function Rera() {
  return (
    <div>
        <Header/>
        <ReraContent/>
        <Footer/>
    </div>
  )
}

export default Rera