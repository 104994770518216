import React from 'react'
import { Col, Row } from 'react-bootstrap'
import smallLogo from '../../assets/Image/small-icon.png'
import Container from '../Container/Container'
import Heading from '../Heading/Heading'
import './BlogInnerHero.scss'

function BlogInnerHero(props) {
  return (
    <div className='bi-hero'>
        <Container>
            <Row>
                <Col sm={12} md={4}>
                    <div className="info-box">
                        <div className="info-box__content">
                            <div className="info-box__content-text">
                                <p>Prime Group</p>
                                <span>24 Jan, 2022</span>
                            </div>
                            
                            <div className="info-box__content-image">
                                <img src={smallLogo} alt="" />
                            </div>
                        </div>
                    </div>
                </Col>

                <Col sm={12} md={8}>
                    <div className="bi-title">
                        <h2>
                            {props.title}
                        </h2>
                    </div>

                    <div className="bi-caption">
                        <p>Latest Blog</p>
                    </div>

                    <div className="bi-intro">
                        {props.intro.length ? 
                            <p>
                                {props.intro}
                            </p>
                        : ''
                        }  
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default BlogInnerHero