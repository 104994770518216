import React from 'react'
import "./HeroSection.scss"

function Herosection() {
  
  return (
    <div className='cnt-hero-section'>

    </div>
  )
}

export default Herosection