import React from 'react'
import Container from '../Container/Container'
import './BlogInnerImages.scss'

function BlogInnerImages(props) {
  return (
    <div className='bi-images'>
        <Container>
            <div className="images-wrapper">
                <div className="image-one">
                    <img src={props.imageOne} alt="" />
                </div>

                <div className="image-two">
                    <img src={props.imageTwo} alt="" />
                </div>
            </div>
            
        </Container>
    </div>
  )
}

export default BlogInnerImages