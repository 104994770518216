import { React, useRef, useEffect } from 'react'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from 'gsap';
import "./Formsection.scss"
import contactpattern from '../../../assets/Image/contact-pattern.png';
import emailjs from '@emailjs/browser';

function FormSection() {

    gsap.registerPlugin(ScrollTrigger);

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_ax515il', 'template_mkkn55b', form.current, '4iSfSAmiinUvzoj96')
          .then((result) => {
              console.log(result.text);
              alert('Thank You for contacting us. We will get back to you in 24 hours')
          }, (error) => {
              console.log(error.text);
          });
      };
    
  return (
    <div className='form-section'>
        <div className="form-section-left">
                <h1>We’d love to hear from you.</h1>
                    <span>  
                        <img src={contactpattern} alt="" />
                    </span>
            </div>
            <div className="form-section-right">
                <form ref={form} onSubmit={sendEmail} className='form-section-right-form'>
                    <div className="form-section-right-form-group">
                        <input required name='from_name' type="text" id="name" placeholder='Name*' />
                    </div>
                    <div className="form-section-right-form-group">
                        <input required name='email' type="email" id="email" placeholder='Email*' />
                    </div>
                    <div className="form-section-right-form-group">
                        <input required name='message' type="text" id="message" placeholder='Message' />
                    </div>
                    <div className="form-section-right-form-group">
                        <button type="submit">Submit &rarr;</button>
                    </div>
                </form>
            </div>
    </div>
  )
}

export default FormSection