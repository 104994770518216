import {React,useRef,useEffect} from 'react'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import  gsap  from 'gsap';
import "./Homeabout.scss"
import Container from '../../Container/Container'
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

function HomeAbout() {

    const parent = useRef()
    const title = useRef();
    const caption = useRef();
    const link = useRef();

    useEffect(() => {
        // ScrollTrigger.refresh(); 


        // const t1 = gsap.timeline({
        //     scrollTrigger: {
        //         trigger: '.home-about',
        //         scrub: false,
        //         // markers: true,
        //         start: `top ${window.innerHeight/2}`,
        //         end: 'bottom bottom'
        //     }
        // });

        const t1 = gsap.timeline({
            scrollTrigger: {
                trigger: '.home-about',
                scrub: false,
                // markers: true,
                start: `top ${window.innerHeight/2}`,
                end: 'bottom bottom'
            }
        });
      
        t1.from('.home-about-left h1 span', {
            opacity: 0,
            y: '20px',
            stagger: 0.04,
        }, 0)
        .from('.home-about-right h3', {
            opacity: 0,
            y: '20px'
        }, 0.5)
        .from('.hover-link', {
            opacity: 0,
            y: '20px'
        }, 0.5)
    
        // ScrollTrigger.killAll();
        // return () => {
        //     ScrollTrigger.kill();
        //   }
      
    }, [])
    return (
        <div className='home-about' ref={parent}>
            <Container>
               
                    <div className="home-about-left">
                        <h1 ref={title}>
                            <span>A</span>
                            <span>B</span>
                            <span>O</span>
                            <span>U</span>
                            <span>T</span>
                            <span> </span>
                            <span>U</span>
                            <span>S</span>
                            
                        </h1>
                    </div>
                    <div className="home-about-right">
                        <h3 ref={caption}>Trustworthy, reliable & passionate
                            about quality. We are guided by the
                            conviction that our customers must
                            be happy and satisfied.</h3>
                        <Link to="/about-us" className='hover-link'>
                            <div ref={link}>
                                Explore More&nbsp;<span className='link-hover'>&rarr;</span>
                            </div>
                        </Link>
                    </div>
           
            </Container>
        </div>
    )
}

export default HomeAbout