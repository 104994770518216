import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Container from '../../Container/Container'
import logoTwo from '../../../assets/Image/logo-2.png'
import logoThree from '../../../assets/Image/logo-3.png'
import logoFour from '../../../assets/Image/logo-4.png'
import blackLogo from '../../../assets/Image/black-logo.png'
import './LogoPhilosophy.scss'

function LogoPhilosophy() {
  return (
    <div className='logo-phil'>
         <div className="logo-phil-left">
            <div className="logo-phil-title">
                
                <h2>Logo Philosophy</h2>
            </div>
            <Row>
                <Col xs={4}>
                    <div className="content-wrapper">
                        <div className="logo">
                            <img src={logoTwo} alt="" />
                        </div>
                        <div className="text">
                            <p>
                            The logo shows the perspective of a room. It dictates the feeling of belongingness.
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={4}>
                    <div className="content-wrapper">
                        <div className="logo">
                            <img src={logoThree} alt="" />
                        </div>
                        <div className="text">
                            <p>
                            The Arc represents an open door that signifies the feeling of being welcomed, home, & family. 
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={4}>
                    <div className="content-wrapper">
                        
                        <div className="logo">
                            <img src={logoFour} alt="" />
                        </div>
                        <div className="text">
                            <p>
                            The shades of blue & green portray care & nature, & how instill nature in our every project. 
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
         </div>
         <div className="logo-phil-right">
            <div className="logo-phil-right-content">
                <div className="logo-phil-title">
                    
                    <h2>L</h2>
                </div>
                <div className="black-logo">
                    <img src={blackLogo} alt="" />
                </div>
                <div className="black-logo-content">
                    <p>
                    It represents the triumphs, resources, and prosperity we want to provide to our clientele.
                    </p>
                </div>
            </div>
         </div>
    </div>
  )
}

export default LogoPhilosophy