import {React, useRef} from 'react'
// import AliceCarousel from 'react-alice-carousel';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./Homehero.scss"


function HomeHero() {
  const line1 = useRef();
  const line2 = useRef();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  return (
    <div className='hero-section'>
      <Carousel responsive={responsive} arrows={false} showDots={true} autoPlay={true}  autoPlaySpeed={5000} transitionDuration={500} infinite={true}>
        <div className='slide-1 slide-bg-1'>
          <div className='hero-section-content'>
              
              <div className='hero-title'>
              <div className='line-1' ref={line1}>
                Building
              </div>
              <div className='line-2' ref={line2}>
                Magnificence
              </div> 
              {/* <div className='line-3' ref={line3}>
                Homes
              </div> */}
            </div>
          </div>
          {/* <img src={image1} alt="" /> */}
        </div>

        <div className='slide-1 slide-bg-2'>
          <div className='hero-section-content'>
              
              <div className='hero-title'>
              <div className='line-1' ref={line1}>
              Eternally Elegant
              </div>
              <div className='line-2' ref={line2}>
                & Luxurious
              </div> 
              {/* <div className='line-3' ref={line3}>
                Luxurious
              </div> */}
            </div>
          </div>
          {/* <img src={image1} alt="" /> */}
        </div>

        <div className='slide-1 slide-bg-3'>
          <div className='hero-section-content'>
              
              <div className='hero-title'>
              <div className='line-1' ref={line1}>
                Your Family
              </div>
              <div className='line-2' ref={line2}>
                Awaits You
              </div> 
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  )
}

export default HomeHero