import React from 'react'
import banner from '../../../assets/Image/primeStatus-banner.jpg'
import "./Banner.scss"

function Banner() {
  return (
    <div className='banner'>
      {/* <img src={banner} alt="" /> */}
       {/* <iframe width="100%" height="100%" src="https://www.youtube.com/embed/0MVXmJjgyZI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
    </div>
  )
}

export default Banner