import React from 'react'
import Container from '../Container/Container'
import leftImg from '../../assets/Image/rera-left.png'
import rightImg from '../../assets/Image/rera-right.png'
import './PraanganRera.scss'

function PraanganRera() {
  return (
    <div className='pr'>
        <img className='left-img' src={leftImg} alt="" />
        <img className='right-img' src={rightImg} alt="" />
        <Container>
            <div className="pr-content">
                <div className="pr-left">
                    <h1>
                        Rera Certificate
                    </h1>
                </div>

                <div className="pr-right">
                    <p>
                        RERA NO.: PR/GJ/AHMEDABAD/AHMEDABAD CITY/AUDA/MAA10774/141022
                    </p>

                    <p>
                        www.gujrera.gujarat.gov.in
                    </p>
                </div>
            </div>
        </Container>
    </div>
  )
}

export default PraanganRera