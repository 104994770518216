import { React, useRef, useEffect } from 'react'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from 'gsap';
import Heading from '../../Heading/Heading'
import "./GallerySection.scss"
import img1 from '../../../assets/Image/primeStatus-g3.png';
import img2 from '../../../assets/Image/primeStatus-g1.png';
import img3 from '../../../assets/Image/primeStatus-g2.jpg';
function GallerySection() {
    
    const parent = useRef();
    
    useEffect(()=> {
        const t1 = gsap.timeline({
            scrollTrigger: {
              trigger: parent.current,
              scrub: false,
              // markers: true,
              start: `top ${(window.innerHeight) * 2/4}`,
              end: 'bottom bottom'
            },
          });

        t1.from('.gallery-section-left-top', {
            opacity: 0,
            y:'20px'
        })
        .from('.gallery-section-left-middle-right', {
            opacity: 0,
            y: '20px'
        }, 0.5)
    }, [])

    return (
        <div className="gallery-section" ref={parent}>

            <div className="gallery-section-left">
                <div className="gallery-section-left-top">
                    <Heading>PRACTICALLY DESIGNED
                        FOR A BETTER LIVING</Heading>
                </div>
                <div className="gallery-section-left-middle">
                    <div className="gallery-section-left-middle-left">

                    </div>
                    <div className="gallery-section-left-middle-right">
                        <p>Residential estate that will definitely wow you. The prime status was 
                            created with the modern lifestyle in mind. Started .gallery-section-left-bottomin 2017, it began 
                            with an aim to create best-in-class houses with  better living culture 
                            and never-before-seen amenities.</p>
                    </div>
                </div>
                <div className="gallery-section-left-bottom">
                    <img src={img3} alt="" />
                </div>

            </div>


            <div className="gallery-section-right">
                <div className="gallery-section-right-top">
                    <img src={img2} alt="" />
                </div>
                <div className="gallery-section-right-bottom">
                    <img src={img1} alt="" />
                </div>
            </div>

        </div>
    )
}

export default GallerySection