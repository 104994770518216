import React from 'react'
import Container from '../Container/Container'
import './BlogHero.scss'

function BlogHero() {
  return (
    <div className='blog-hero'>
        <Container>
            <div className="blog-hero-content">
                <div className="blog-main-title">
                    <h2>
                        Collection of <br/>Blogs to Read
                    </h2>
                </div>
            </div>
        </Container>
        
    </div>
  )
}

export default BlogHero