import React from 'react'
import praanganHapp from '../../assets/Image/praangan-happ.jpg'
import PraanganBrochure from '../../assets/praangan_brochure.pdf'
import Popup from '../Popup/Popup'
import pdf from '../../assets/praangan_brochure.pdf'
import './PraanganInfo.scss'

function PraanganInfo() {
  return (
    <div className='pi'>
        <div className="pi-wrapper">
            <div className="pi-left">
                <div className="pi-title">
                    <h2>The Courtyard of Happiness</h2>
                </div>
                <div className="pi-content">
                    <p>Nestled in the heart of Nature, Praangan promises a world of comfort and luxury.
                    </p>
                    <p>
                        Praangan presents meticulously designed brilliant homes for you & your families, embedded with the perfect blend of modern comforts, traditional  good tastes, and spacious aangans.
                    </p>
                </div>

                <div className="pi-download"> 
                    <h3><Popup brochure={pdf}/></h3>
                </div>
            </div>

            <div className="pi-right">
                <img src={praanganHapp} alt="" />
            </div>
        </div>

    </div>
  )
}

export default PraanganInfo