import {React, useRef, useEffect} from 'react'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import  gsap  from 'gsap';
import "./Testimonial.scss"
import AliceCarousel from 'react-alice-carousel'
import Container from '../../Container/Container'
import quotation from '../../../assets/Image/quotation.png';
import Heading from '../../Heading/Heading';

gsap.registerPlugin(ScrollTrigger);

function Testimonial() {
    
    const bg = useRef();
    const content = useRef();

    useEffect(()=> {

    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: bg.current,
        scrub: false,
        // markers: true,
        start: `top ${(window.innerHeight) * 3/4}`,
        end: 'bottom bottom'
      },
      
    });


    t1.from(".test-header span", {
        opacity: 0,
        y: '20px',
        stagger: 0.04,
      })

      .from(content.current, {
        opacity: 0,
        y: '20px'
    }, 0.5)

    
    },[])

    

    const responsive = {
        0: {
            items: 1
        },
        1024: {
            items: 2
        }
      };

      const items = [
        <div className='testimonial-content-left'>
            <span>
                <img src={quotation} alt="" />
            </span>
            <div>
                <h3>
                I'm Mukesh Panchal of Loft Gardens… And, the only thing I can say about Prime Group is that the builder makes no compromises on the quality of labor, construction, especially detailing, or the architect. I am glad that I made the choice of Loft Gardens
                </h3>
                <p>Mukesh Panchal, Loft Gardens</p>
            </div>
        </div>,

        
        <div className='testimonial-content-right'>
            <span><img src={quotation} alt="" /></span>
            <div>
                <h3>
                The site of Prime Status Society is, as the name suggests, quite prime. The society has a wonderful eco-friendly green atmosphere. In comparison to others, the construction is excellent and got timely possession. The entire building and units are well-planned and roomy. I am quite pleased with the quality of work of Prime Group. 
                </h3>
                <p>Dixit Patel , Prime Status.</p>
            </div>
        </div>,

        <div className='testimonial-content-left'>
            <span>
                <img src={quotation} alt="" />
            </span>
            <div>
                <h3>
                "Loft Gardens" is synonymous with assurance and excellence. It is a place that makes individuals feel better about themselves.
                </h3>
                <p>Deep Kothari, Loft Gardens</p>
            </div>
        </div>

      ];
    return (
        <div className='testimonial' ref={bg}>
            <Container>
                <Heading>
                    <div className="test-header">
                        <span>T</span>    
                        <span>E</span>    
                        <span>S</span>    
                        <span>T</span>    
                        <span>I</span>    
                        <span>M</span>    
                        <span>O</span>    
                        <span>N</span>    
                        <span>I</span>    
                        <span>A</span>    
                        <span>L</span>    
                        <span>S</span>    
                          
                    </div>    
                </Heading> 
                <div className='testimonial-content' ref={content}>
                   
                    <AliceCarousel infinite={true} responsive={responsive} items={items} autoPlay={true} autoPlayInterval={3000}/>
                    
                </div>
            </Container>
        </div>
    )
}

export default Testimonial