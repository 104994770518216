import React from 'react'
import { Route, Routes, useLocation } from "react-router-dom";
import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Projects from '../pages/Projects';
// import ScrollToTop from './components/ScrollToTop';
import Blogs from '../pages/Blogs';
import BlogsInner from '../pages/BlogsInner';
import PrimeStatus from '../pages/PrimeStatus';
import Rera from '../pages/Rera';
import {AnimatePresence} from 'framer-motion'
import Praangan from '../pages/Praangan';

function AnimatedRoutes() {
    const location = useLocation();

  return (
    <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/about-us" element={<About/>} />
            <Route exact path="/contact-us" element={<Contact/>} />
            <Route exact path="/loft-gardens" element={<Projects/>} />
            <Route exact path="/blogs" element={<Blogs/>} />
            <Route exact path="/blogs/:id" element={<BlogsInner/>} />
            {/* <Route exact path="/blog-inner/:id" element={<BlogsInner/>} /> */}
            <Route exact path="/prime-status" element={<PrimeStatus/>} />
            <Route exact path="/praangan" element={<Praangan/>} />
            <Route exact path="/rera" element={<Rera/>} />
            <Route exact path="*" element={
              <>
                404 Page Not found  
              </>
              } />
        </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes